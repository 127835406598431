import { Container } from 'react-bootstrap';
import * as Paths from "../../../../constants/paths";

function TermsAndConditionsDetailsBox() {

  // #region Templates

  const detailsTemplate = (
    <>
      <Container className='p-5'>         
        <p className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
          <i>
            <b className='app-secondary-text'>La Gran Vida</b>., con domicilio en Cerrada del Avellano #824 Cerradas De Anahuac General Escobedo, NL. C.P:66059 y sitio web <b><a href='https://www.lagranvida.com' target='_self'>https://www.lagranvida.com</a></b> en cualquier otro subdominio, dominio vinculado o redireccionado al mismo (en adelante la “Plataforma”) ponemos a tu disposición los siguientes Términos y Condiciones de Uso.
          </i>
        </p>
        <p className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
          En este acto reconoces haber leído y aceptado el Aviso de Privacidad aplicable para el tratamiento de tus datos personales, disponible en <b><a href='https://www.lagranvida.com//#/AvisoDePrivacidad' target='_self'>https://www.lagranvida.com//#/AvisoDePrivacidad</a></b>, que, junto con los Términos y Condiciones de Uso, formarán el acuerdo total entre tú y La Gran Vida (el “Contrato”).
        </p>
      
        <h1
          id={Paths.sections.aboutUs.WHO_ARE_WE}
          className='app-anton-regular-font app-secondary-text'
        >
          Contenido
        </h1>  
        <ol className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
          <li>            
            Aceptación de los Términos y Condiciones de Uso.
          </li>
          <li>            
            Definiciones.
          </li>
          <li>            
            Naturaleza de la Plataforma.
          </li>
          <li>            
            Reglas sobre uso de la Plataforma.
          </li>
          <li>            
            Elaboración de un Perfil de Usuario.
          </li>
          <li>            
            Contraseña.
          </li>
          <li>            
            Baja de Perfil de Usuario.
          </li>
          <li>            
            Beneficios.
          </li>
          <li>            
            Costo de Suscripción.
          </li>
          <li>            
            Información sobre los Cursos, talleres y demás Servicios.
          </li>
          <li>            
            Compra de Programas, Cursos y Servicios en general.
          </li>
          <li>            
            Políticas de facturación electrónica.
          </li>
          <li>            
            Cancelación de compra de Programas, Cursos o Servicios.
          </li>
          <li>            
            Medio de comunicación oficial y correos publicitarios.
          </li>
          <li>            
            Obligaciones sobre los recursos y equipo.
          </li>
          <li>            
            Código de conducta.
          </li>
          <li>            
            Hacking.
          </li>
          <li>            
            Responsabilidades del Usuario.
          </li>
          <li>            
            Descargo y límite de la responsabilidad de La Gran Vida.
          </li>
          <li>            
            Responsabilidad por factores externos.
          </li>
          <li>            
            Violaciones.
          </li>
          <li>            
            Propiedad intelectual.
          </li>
          <li>            
            Material publicitario.
          </li>
          <li>            
            Licencia limitada de la Plataforma.
          </li>
          <li>            
            Confidencialidad.
          </li>
          <li>            
            No asociación.
          </li>
          <li>            
            Cesión de derechos.
          </li>
          <li>            
            Disposiciones ineficaces.
          </li>
          <li>            
            Términos adicionales.
          </li>
          <li>            
            No renuncia de derechos.
          </li>
          <li>            
            Vigencia.
          </li>
          <li>            
            Títulos de los apartados.
          </li>
          <li>            
            Fuerza mayor.
          </li>
          <li>            
            Comentarios, quejas y sugerencias.
          </li>
          <li>            
            Jurisdicción aplicable.
          </li>          
        </ol>

        <h1 className='app-anton-regular-font app-secondary-text'>
          1. ACEPTACIÓN DE LOS TÉRMINOS Y CONDICIONES DE USO.
        </h1> 
        <p className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
          Al ingresar, navegar y/o utilizar los Servicios de nuestra Plataforma estás aceptando los Términos y Condiciones de Uso contenidos en este documento y estás declarando expresamente tu aceptación, utilizando para tal efecto medios electrónicos, en términos de lo dispuesto en el artículo 1,803 y demás relativos del Código Civil Federal.
        </p>
        <p className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
          En caso de no aceptar en forma absoluta y completa los Términos y Condiciones de Uso, deberás abstenerte de acceder, utilizar y/o navegar en la Plataforma y/o hacer uso de cualquier otro servicio que ofrezca La Gran Vida.
        </p>
        <p className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
          La Gran Vida no guardará una copia individualizada de los presentes Términos y Condiciones de Uso, por lo que te recomendamos guardar una copia para tu propio expediente.
        </p>
        <p className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
          En caso de que violes lo expresado en estos Términos y Condiciones de Uso, aceptas que La Gran Vida podrá cancelar tu acceso, así como excluirte de futuros Servicios, y/o tomar la acción legal que juzgue conveniente para sus intereses.
        </p>
        <p className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
          <b className='app-secondary-text'>
            La Gran Vida es una empresa dedicada a ofrecer opiniones personales de diversa índole, por lo que en ningún momento ofrece, ni ofrecerá, asesoramiento médico o clínico, debido a lo anterior entiendes que las opiniones realizadas NO sustituyen el consejo, diagnóstico o tratamiento profesional de ninguna condición médica o psicológica. No debes dejar de buscar ayuda profesional por algo que hayas escuchado o leído en nuestra Plataforma.
          </b>
        </p>

        <h1 className='app-anton-regular-font app-secondary-text'>
          2.DEFINICIONES.
        </h1> 
        <p className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
          Para facilidad de lectura, en La Gran Vida te proporcionamos las definiciones de los conceptos que aparecerán a lo largo del presente documento. Las siguientes palabras deberán ser interpretadas según se define en este apartado. Para efectos de diferenciación, dichos términos serán descritos con la primera letra en mayúscula y únicamente cuando se utilicen con dicho formato es cuando deberán de interpretarse como tales.
        </p>
        <p className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
          Los términos definidos se utilizarán sin importar el género o número gramatical con el que se expresen, sin que esto afecte su significado, debiendo prevalecer el significado que se le dio en el presente Contrato.
        </p>
        <table className='table table-bordered app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
          <thead>
            <tr className='app-secondary-text'>
              <th scope="col">Término</th>
              <th scope="col">Definición</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th scope="row">
                “Aviso de Privacidad”
              </th>
              <td>
                Es el Aviso de Privacidad que contiene la información respecto al tratamiento de tus datos personales recabados por La Gran Vida, mediante la Plataforma, mismo que puede ser consultado en el siguiente enlace: <b><a href='https://www.lagranvida.com//#/AvisoDePrivacidad' target='_self'>https://www.lagranvida.com//#/AvisoDePrivacidad</a></b>
              </td>
            </tr>
            <tr>
              <th scope="row">
                “Beneficios”
              </th>
              <td>
                Son aquellas promociones, descuentos, precios especiales, etc., que son ofrecidos por La Gran Vida a los Usuarios registrados en la Plataforma.
              </td>
            </tr>
            <tr>
              <th scope="row">
                “Blog”
              </th>
              <td>
                Es un espacio, dentro de la Plataforma, creado por La Gran Vida que contiene notas, reflexiones e información que consideramos que puede ser de tu interés.
              </td>
            </tr>
            <tr>
              <th scope="row">
                “Contrato”
              </th>
              <td>
                Es el acuerdo total entre Tú y La Gran Vida, respecto de los presentes Términos y Condiciones de Uso y el Aviso de Privacidad.
              </td>
            </tr>
            <tr>
              <th scope="row">
                “Programas”
              </th>
              <td>
                <p>
                  Son el conjunto de lecciones o pláticas sobre temas de salud mental, salud emocional, salud espiritual, salud física, relaciones de pareja (de todo tipo), maternidad y paternidad consciente, crianza, nutrición, misticismo y todo aquello que pueda ayudar al Usuario a mejorar su calidad de vida interior. Los Cursos pueden ser impartidos indistintamente por personas con experiencia o por especialistas de la materia.
                </p>
                <p>
                  La finalidad de los Cursos es que cuestiones tus creencias actuales, y presentarte diferentes puntos de vista que te lleven a la reflexión. Ninguno de los Cursos tiene validez oficial ni deben considerarse como la única opción o respuesta a tu entorno.
                </p>
              </td>
            </tr>
            <tr>
              <th scope="row">
                “Episodios”
              </th>
              <td>
                Son aquellas cargas de información por parte de La Gran Vida, que de manera consecutiva, versan sobre temas de salud mental, emocional, espiritual y física y que pueden ser presentadas por personas con experiencias personales o por profesionales en alguna materia.
              </td>
            </tr>
            <tr>
              <th scope="row">
                “Cursos”
              </th>
              <td>

              </td>
            </tr>
            <tr>
              <th scope="row">
                “Facilitadores”
              </th>
              <td>
                Es la persona que presenta diferentes puntos de vista que te hagan cuestionar tus creencias y te lleven a la reflexión; proporcionándote herramientas que te ayuden a sobrellevar tu desarrollo personal. Los facilitadores pueden hablar de experiencias personales o ser expertos en la materia, sin embargo, en LMDC te los presentamos para expandir tus conocimientos y no como una alternativa única.
              </td>
            </tr>
            <tr>
              <th scope="row">
                “La Gran Vida”
              </th>
              <td>
                Es la Sociedad Civil , denominada Camino a LAGV S.C., propietaria de la marca registrada La Gran Vida® y de la Plataforma.
              </td>
            </tr>
            <tr>
              <th scope="row">
                “Material Publicitario”
              </th>
              <td>
                Son las imágenes, anuncios y demás material publicitario o promocional de los Patrocinadores de La Gran Vida.
              </td>
            </tr>
            <tr>
              <th scope="row">
                “México”
              </th>
              <td>
                Se refiere a los Estados Unidos Mexicanos.
              </td>
            </tr>
            <tr>
              <th scope="row">
                “Perfil de Usuario”
              </th>
              <td>
                Es el perfil a través del cual el Usuario hace uso de la Plataforma y los Servicios que en ella se encuentran.
              </td>
            </tr>
            <tr>
              <th scope="row">
                “Plataforma”
              </th>
              <td>
                Es el dominio <b><a href='https://www.lagranvida.com' target='_self'>https://www.lagranvida.com</a></b>, cualquier otro subdominio, dominio vinculado o redireccionado a la misma.
              </td>
            </tr>
            <tr>
              <th scope="row">
                “Podcast”
              </th>
              <td>
                Significa la distribución de audio y video, usualmente conocido como podcast a través de plataformas y Redes Sociales.
              </td>
            </tr>
            <tr>
              <th scope="row">
                “Proveedores de Servicios de Pagos”
              </th>
              <td>
                Es la empresa encargada de procesar los pagos realizados por los Usuarios, por medio de la Plataforma.
              </td>
            </tr>
            <tr>
              <th scope="row">
                “Redes Sociales”
              </th>
              <td>
                Se refiere a todo aquel espacio en internet dedicado a la constante vinculación de individuos para la constante manifestación e intercambio de ideas, así como información diversa con finalidades de entretenimiento, ocio, concientización social y formación de relaciones interpersonales, incluyendo sin limitar, Facebook®, Instagram®, Tiktok®, Linked in®, Correo electrónico y WhatsApp®.
              </td>
            </tr>
            <tr>
              <th scope="row">
                “Sponsor”
              </th>
              <td>
                Se refiere a aquellos patrocinadores, anunciantes y terceros con los que La Gran Vida haya realizado un Convenio para apoyarse mutuamente.
              </td>
            </tr>
            <tr>
              <th scope="row">
                “Suscripción”
              </th>
              <td>
                Es un modelo de presentación en la cual el Usuario tiene acceso a Servicios exclusivos por parte de La Gran Vida.
              </td>
            </tr>
            <tr>
              <th scope="row">
                “Terceros”
              </th>
              <td>
                Cualquier persona física o moral que tenga una relación comercial directa o indirectamente con La Gran Vida, tales como prestadores de Servicios, socios comerciales, etc.
              </td>
            </tr>
            <tr>
              <th scope="row">
                “Usuario”
              </th>
              <td>
                Aquella persona física, de manera indistinta y sin hacer referencia entre cualquier identidad de género o sexual, que utiliza la Plataforma y los Servicios que en la misma se ofrecen.
              </td>
            </tr>
          </tbody>
        </table>

        <h1 className='app-anton-regular-font app-secondary-text'>
          3.NATURALEZA DE LA PLATAFORMA.
        </h1> 
        <p className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
          La Gran Vida es una Plataforma digital de autoayuda, diseñada para que los Usuarios puedan escuchar, leer y aprender respecto a diversos temas, entre los que se encuentran la salud mental, emocional, espiritual, física, relaciones interpersonales, nutrición, misticismo, las crisis que surgen a raíz de estos temas y todo aquello que te ayude en tu proceso de desarrollo personal. (los “Servicios”).
        </p>        
        <p className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
          Así mismo, por medio de la Plataforma podrás encontrar información respecto a los Programas, Cursos y demás Servicios que ofrece La Gran Vida.
        </p>        
        <p className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
          Al hacer uso de la Plataforma reconoces y entiendes que La Gran Vida, la Plataforma y su contenido, son única y exclusivamente de carácter informativo y en ninguna circunstancia deberán interpretarse como un diagnóstico, ni considerarse un sustituto de asesoría y evaluación de un profesionista certificado.
        </p>        
        <p className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
          <b className='app-secondary-text'>
            Te recomendamos siempre buscar el consejo de tu médico o especialista para cualquier pregunta relacionada con tu estado de salud física o mental. Nunca deberás ignorar el consejo de un médico o dejar de buscar ayuda profesional por algo que hayas leído, visto o escuchado en La Gran Vida.
          </b>
        </p>

        <h1 className='app-anton-regular-font app-secondary-text'>
          4.REGLAS SOBRE USO DE LA PLATAFORMA.
        </h1> 
        <p className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
          Al usar nuestra Plataforma te estás obligando a hacer uso de esta de forma diligente, correcta, lícita, y conforme a la moral y las buenas costumbres, de conformidad con las leyes mexicanas. Por lo que te obligas a responder por los daños y perjuicios de toda naturaleza que La Gran Vida pudiera sufrir, directa o indirectamente, como consecuencia del incumplimiento de cualquiera de las obligaciones derivadas del presente Contrato.
        </p>
        <p className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
          De conformidad con las leyes de México, el acceso y uso de los Servicios y de la Plataforma está dirigido únicamente a personas mayores de 18 (dieciocho) años. Derivado de lo anterior, al utilizar la Plataforma manifiestas bajo protesta de decir verdad, que eres mayor de 18 (dieciocho) años y cuentas con la capacidad legal necesaria para entender y aceptar el presente Contrato.
        </p>
        <p className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
          La Gran Vida toma la privacidad y protección de tus datos, de manera muy seria, es por esto por lo que ponemos a tu disposición el Aviso de Privacidad disponible en <b><a href='https://www.lagranvida.com//#/AvisoDePrivacidad' target='_self'>https://www.lagranvida.com//#/AvisoDePrivacidad</a></b>, mismo que te sugerimos leer antes de hacer uso de nuestra Plataforma y demás Servicios.
        </p>
        <p className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
          Para garantizar la correcta prestación de los Servicios, te recomendamos autorizar los accesos necesarios y suficientes en tu computadora, dispositivo móvil y/o tableta.
        </p>
        <h2 className='app-anton-regular-font app-secondary-text'>
          RESTRICCIONES PARA MENORES.
        </h2> 
        <p className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
          La Gran Vida no realiza ventas de forma intencional a menores de edad, por lo que se recomienda a los padres y tutores que sean ustedes quienes lleven a cabo las actividades de compra de experiencias o cursos y/o registro en la Plataforma. En caso de que seas menor de 18 (dieciocho) años, La Gran Vida entiende que cuentas con el consentimiento expreso de tus padres o tutores.
        </p>
        <p className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
          Los padres o tutores de menores de edad serán responsables de los actos realizados por los menores a su cargo, según lo dispuesto por estos Términos y Condiciones de Uso, incluyendo los daños causados a terceros y/o que estén prohibidos por ley y/o por las disposiciones de este acuerdo. Si eres padre o tutor de un menor de edad, y tienes conocimiento de que el menor de edad a tu cargo hizo uso de nuestra plataforma o los servicios contenidos en la misma, sin tu consentimiento, te pedimos te pongas en contacto con nosotros al correo electrónico <b className='app-secondary-text'><a href="mailto:hola@lagranvida.com">hola@lagranvida.com</a></b> a fin de poderte ayudar, si así lo deseas, a dar de baja el perfil de usuario y eliminar la información personal del menor de edad.
        </p>

        <h1 className='app-anton-regular-font app-secondary-text'>
          5.ELABORACIÓN DE UN PERFIL DE USUARIO.
        </h1> 
        <p className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
          Para hacer uso de los Servicios, deberás crear un Perfil, seleccionando la opción “Registrarse” y realizando lo siguiente:
        </p>
        <ul className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
          <li>
            Paso 1. Deberás llenar un formulario de registro, en el que se te solicitarán datos personales como tu nombre, correo electrónico, teléfono, y contraseña;
          </li>
          <li>
            Paso 2. La Gran Vida te confirmará que un Perfil de Usuario se encuentra debidamente dado de alta, emitiendo el documento correspondiente, mismo que será enviado por correo electrónico.
          </li>
        </ul>    
        <p className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
          Durante el proceso de registro, estás aceptando recibir correos electrónicos promocionales de la Plataforma, no obstante, posteriormente, podrás optar por no recibir tales correos promocionales, haciendo clic en el enlace de la parte inferior del mismo correo.
        </p>
        <p className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
          El uso que se haga de tu Perfil de Usuario es completamente tu responsabilidad, por lo que te comprometes a notificarnos inmediatamente cualquier uso no autorizado de tu Perfil de Usuario. La Gran Vida no puede garantizar la identidad de los Usuarios registrados, por tanto, no somos ni seremos responsables del uso de la identidad de un Perfil de Usuario registrado por terceros.
        </p>
        <p className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
          Así mismo, te comprometes a no hacer nada que ayude a alguien que no es un Usuario registrado a acceder a cualquier zona de seguridad de la Plataforma, y, no crear Perfiles de Usuario adicionales con el fin de abusar de la funcionalidad de la Plataforma, o de otros Usuarios, o para tratar de hacerte pasar por otro Usuario. Si consideras o sospechas que alguien más tiene acceso a tu Perfil de Usuario, por favor háznoslo saber inmediatamente para que cancelemos tu Perfil de Usuario tan pronto como sea posible.
        </p>

        <h1 className='app-anton-regular-font app-secondary-text'>
          6.CONTRASEÑA.
        </h1> 
        <p className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
          En este acto, te estás obligando a mantener tu contraseña segura, confidencial y no permitir que ninguna otra persona haga uso de tu dirección de correo electrónico o contraseña para acceder a la Plataforma. Eres totalmente responsable de todas las actividades que ocurran bajo tu nombre, correo o Perfil de Usuario, por lo que es sumamente importante que nos notifiques cualquier uso no autorizado de tu contraseña o Perfil de Usuario. La Gran Vida no seremos responsables, directos o indirectamente, por cualquier pérdida o daño de cualquier tipo incurridos como resultado de la falta de cumplimiento con esta sección.
        </p>
        <p className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
          En caso de sustracción, divulgación o pérdida de tu contraseña o nombre de usuario, te obligas a hacerlo de nuestro conocimiento, comunicándolo inmediatamente al siguiente correo electrónico: <b className='app-secondary-text'><a href="mailto:hola@lagranvida.com">hola@lagranvida.com</a></b>.
        </p>

        <h1 className='app-anton-regular-font app-secondary-text'>
          7.BAJA DE PERFIL DE USUARIO.
        </h1>
        <p className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
          La Suscripción a la Plataforma se mantendrá activa siempre que el Usuario no cancele su Suscripción. En caso de así decidirlo, podrás solicitar la eliminación de tu Perfil de Usuario, ingresando al menú de configuración. El sistema te pedirá que ingreses el motivo por el cual deseas eliminar tu Perfil de Usuario y una vez incluida la razón, tu perfil será eliminado.
        </p>

        <h1 className='app-anton-regular-font app-secondary-text'>
          8.BENEFICIOS.
        </h1>
        <p className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
          La Gran Vida, de manera quincenal, y con la intención de poner nuestro granito de arena para que tengas momentos de reflexión sobre distintos temas que te ayudarán en tu desarrollo personal, te estará enviando un correo electrónico con el nuevo episodio, novedades y promociones que surjan en La Gran Vida.
        </p>

        <h1 className='app-anton-regular-font app-secondary-text'>
          9.COSTO DE SUSCRIPCIÓN.
        </h1>
        <p className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
          No existe costo de suscripción. Sin embargo, en La Gran Vida nos reservamos el derecho de modificar los planes e incluir costos de Suscripción en cualquier momento, notificándose de dicha situación, mediante aviso en la Plataforma, dentro de los treinta días naturales previos a la modificación. Cualquier modificación en los precios o en los planes de suscripción se reflejará en el sistema una vez concluidos los treinta días naturales siguientes a la notificación.
        </p>

        <h1 className='app-anton-regular-font app-secondary-text'>
          10.INFORMACIÓN SOBRE LOS CURSOS, PROGRAMAS Y DEMÁS SERVICIOS.
        </h1>
        <p className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
          La información dada sobre cada Curso, Programas y sobre los Servicios, así como las fotografías o vídeos relativos a los mismos y los nombres comerciales, marcas o signos distintivos de cualquier clase contenidos en la Plataforma, son expuestos de modo exclusivamente orientativo.
        </p>
        <p className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
          La Gran Vida te informa que, a partir de que inicies un Curso o programa, contarás con el tiempo indicado en sí mismo para concluirlo, en caso contrario, en La Gran Vida nos reservamos el derecho de mantener el Curso vigente, o no, dentro de la Plataforma, una vez concluidos el tiempo establecido para cada uno, se podrá cerrará tu acceso al Curso, debiendo, en caso de así requerirlo realizar una nueva contratación.
        </p>

        <h1 className='app-anton-regular-font app-secondary-text'>
          11.COMPRA DE PROGRAMAS, CURSOS Y SERVICIOS EN GENERAL.
        </h1>
        <p className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
          Para poder realizar la compra de los Programas, Cursos yo cualquier servicio ofertado dentro de la Plataforma, deberás realizar el pago correspondiente a través de los Proveedores de Servicios de Pagos que en cada caso se indican, siendo en general:
        </p>
        <ul className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
          <li>
            Tarjeta de crédito o débito,
          </li>
          <li>
            Transferencia electrónica SPEI a la cuenta de La Gran Vida.
          </li>
          <li>
            Plataformas de pago electrónicas (PayPal, Mercado pago, etc.)
          </li>
        </ul>
        <p className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
          Cada vez que recibamos un pago, éste será confirmado vía correo electrónico. Todas los programas, Cursos y Servicios ofertados, tendrán los precios que se muestran en la Plataforma. Puedes actualizar tu forma de pago en tu Perfil de Usuario, con cualquier modificación y/o actualización que realices al respecto, entendemos que autorizas a La Gran Vida a realizar los cargos a las formas de pago correspondientes, de acuerdo con tu suscripción.
        </p>

        <h1 className='app-anton-regular-font app-secondary-text'>
          12.POLÍTICAS DE FACTURACIÓN ELECTRÓNICA.
        </h1>
        <p className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
          Para poder solicitar la emisión de tu factura, deberás enviar un correo al mail <b className='app-secondary-text'><a href="mailto:hola@lagranvida.com">hola@lagranvida.com</a></b> incluyendo la siguiente información:
        </p>
        <ul className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
          <li>
            Servicio o producto adquirido;
          </li>
          <li>
            Orden de compra;
          </li>
          <li>
            R.F.C. con Homo clave;
          </li>
          <li>
            Nombre completo o razón social;
          </li>
          <li>
            Domicilio fiscal, incluyendo el Código Postal:
          </li>
          <li>
            Uso fiscal de la factura;
          </li>
          <li>
            Régimen Fiscal de la persona que recibe la factura; y
          </li>
          <li>
            Correo electrónico.
          </li>
        </ul>

        <h1 className='app-anton-regular-font app-secondary-text'>
          13.CANCELACIÓN / DEVOLUCIÓN DE COMPRA DE PROGRAMAS, CURSOS O SERVICIOS.
        </h1>


        <p className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
          Puedes realizar la cancelación o devolución de tu compra directamente desde la Plataforma o enviando un mail al correo <b className='app-secondary-text'><a href="mailto:hola@lagranvida.com">hola@lagranvida.com</a></b>, dentro de las 24 (veinticuatro) horas siguientes al pago, señalando los motivos por el cual quieres solicitar tu cancelación y adjuntando evidencia que la respalde, tomando en cuenta las políticas de cancelación específicas a cada caso, las cuales podrás consultar previo a tu compra. En caso de que la solicitud de cancelación o devolución de compra sea aceptada, procederemos a realizar el reembolso correspondiente.
        </p>
        <p className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
          En el caso de los Cursos, para poder solicitar la cancelación y reembolso de tu compra, además de realizar tu solicitud en el plazo establecido, no deberás haber ingresado al curso.
        </p>
        <p className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
          Si el pago fue efectuado por tarjeta de débito o crédito, el reembolso lo realizaremos a través de una devolución de cargo en la misma tarjeta que fue utilizada. Si el pago fue efectuado por otros medios, el reembolso se realizará a través de una transferencia bancaria, a la cuenta que para tal efecto señales.
        </p>
        <p className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
          Plazo para reembolsos.
        </p>
        <p className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
          En caso de que en La Gran Vida hayamos validado la garantía y vigencia del proceso, realizaremos el reembolso correspondiente dentro de los 60 (sesenta) días naturales siguientes contados a partir de la fecha en la que La Gran Vida valide la procedencia de la cancelación.
        </p>

        <h1 className='app-anton-regular-font app-secondary-text'>
          14.MEDIO DE COMUNICACIÓN OFICIAL Y CORREOS PUBLICITARIOS.
        </h1>
        <p className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
          Al utilizar la Plataforma estás aceptando y reconociendo que el correo electrónico que registraste al momento de dar de alta tu Perfil de Usuario será el medio oficial de comunicación entre tú y La Gran Vida, por lo que deberás mantener, en todo momento, tu correo electrónico vigente y funcional, entendiéndose que, por el simple hecho de recibirlo en el servidor de tu correo electrónico se entenderá como efectivamente recibido y aceptado, sin necesidad de que lo leas, o no.
        </p>

        <h1 className='app-anton-regular-font app-secondary-text'>
          15.OBLIGACIONES SOBRE LOS RECURSOS Y EQUIPO.
        </h1>
        <p className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
          Al utilizar la Plataforma reconoces y aceptas que para poder hacer un uso adecuado y óptimo de los Servicios que ofrecemos, así como para acceder a los Cursos disponibles, deberás contar con:
        </p>
        <ul className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
          <li>
            Conexión estable a Internet; y
          </li>
          <li>
            JavaScript, cache y cookies activas.
          </li>
        </ul>
        <p className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
          En La Gran Vida no somos responsables por la calidad inadecuada del equipo, o en los casos en los que la conexión a internet que utilices dificulte o impida el acceso a los Servicios.
        </p>

        <h1 className='app-anton-regular-font app-secondary-text'>
          16.CÓDIGO DE CONDUCTA.
        </h1>
        <p className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
          Al utilizar o navegar en la Plataforma, te estás obligando a observar y obedecer los siguientes lineamientos de conducta:
        </p>
        <ul className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
          <li>
            Hacer de nuestro conocimiento cualquier imprevisto, falla técnica y/o cualquier otra información relevante que pudiera impedir, impida o afecte la prestación de los Servicios.
          </li>
          <li>
            No usurpar la identidad de otro Usuario.
          </li>
          <li>
            No vender, arrendar, modificar, suprimir, prestar, revelar, transmitir o transferir contenido, material, o derechos de propiedad intelectual de la Plataforma.
          </li>
          <li>
            No difundir contenido o propaganda de carácter racista, xenófobo, pornográfico, apología del terrorismo o contrarios a los derechos humanos. De las expresiones, opiniones o comentarios incorporados ilícitamente responderán quienes los realizan, La Gran Vida nos limitaremos a realizar, en su caso, la notificación correspondiente a las autoridades competentes.
          </li>
          <li>
            No realizar actos contrarios a los derechos de Propiedad Intelectual y/o Industrial de sus legítimos titulares.
          </li>
          <li>
            No utilizar la Plataforma, así como los contenidos y Servicios ofrecidos a través de esta de forma que pueda impedir su normal funcionamiento o lesionar los bienes y/o derechos de La Gran Vida, de sus proveedores o, en general, de cualquier Tercero.
          </li>
        </ul>
        <p className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
          La inobservancia del Código de Conducta podría conllevar que La Gran Vida cancele, restrinja o limite tu acceso a los Servicios de la Plataforma, así como excluirte de futuras operaciones, y/o tomar la acción legal que juzgue conveniente para sus intereses terceros.
        </p>

        <h1 className='app-anton-regular-font app-secondary-text'>
          17.HACKING.
        </h1>      
        <p className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
          Al hacer uso de la Plataforma reconoces y aceptas que se encuentra prohibido el violar o intentar violar la seguridad de la Plataforma, así como: (a) acceder a datos a los cuales no te encuentres autorizado para utilizar o iniciar sesión o adquirir mercancías en un servidor o en una cuenta para la que el Usuario no tiene acceso autorizado; (b) intentar examinar, escanear o probar la vulnerabilidad de un sistema de informática o de una red o quebrantar las medidas de seguridad o autenticación sin la debida autorización; (c) intentar interferir con el uso de cualquier otro Usuario, servicio de hospedaje o red, incluyendo, sin limitación, el transmitir un virus a la Plataforma o a los sitios web afiliados de La Gran Vida; causar una saturación de dichos sitios mediante "inundación" (flooding), "envío de correo no deseado" (spamming), "bombardeo de correo" (mailbombing) o "generación de fallas" (crashing); (d) el envío de correos electrónicos no solicitados, incluyendo promociones y/o publicidad, o; (e) falsificar cualquier encabezado de paquete TCP/IP o cualquier parte de la información del encabezado en cualquier correo electrónico o publicación en grupo de noticias.
        </p>      
        <p className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
          Reconoces que las violaciones del sistema de informática o de la seguridad de la red pueden generar responsabilidades civiles o penales. En La Gran Vida investigaremos situaciones que puedan involucrar dichas violaciones y nos reservamos el derecho de denunciar tales acciones a las autoridades; La Gran Vida cooperará con la autoridad competente en la investigación de dichas violaciones en los términos establecidos en la legislación aplicable.
        </p>      
        <p className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
          De igual forma, en La Gran Vida nos reservamos el derecho de bloquear el acceso o remover en forma parcial o total toda información, comunicación o material que a su exclusivo juicio pueda resultar: (i) abusivo, difamatorio u obsceno; (ii) fraudulento, artificioso o engañoso; (iii) violatorio de derechos de autor, marcas, confidencialidad, secretos industriales o cualquier derecho de propiedad intelectual de un tercero; (iv) ofensivo; o (v) que de cualquier forma contravenga lo establecido en este Contrato.
        </p>      
        <p className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
          En La Gran Vida no podemos garantizar que el contenido de la Plataforma pueda ser legalmente visto fuera de los Estados Unidos Mexicanos. El acceso al contenido puede no ser legal para ciertas personas o en ciertos países. Si tienes acceso al contenido desde fuera de México, lo haces bajo tu propio riesgo y eres responsable por el cumplimiento de las leyes dentro de la jurisdicción en la que te encuentres.
        </p>

        <h1 className='app-anton-regular-font app-secondary-text'>
          18.RESPONSABILIDADES DEL USUARIO.
        </h1>   
        <p className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
          En este acto, haces constar que entiendes, aceptas y reconoces que eres el único responsable del cumplimiento de las leyes locales o nacionales mexicanas y las aplicables a tu ubicación, por lo que deberás responder por los daños y perjuicios de toda naturaleza que La Gran Vida, sus funcionarios, directores, empleados, contratistas y/o proveedores (incluyendo sin limitación el proveedor del software) pudieran sufrir, directa o indirectamente, así como de las pérdidas, costos, gastos, reclamaciones, demandas, responsabilidades (incluyendo gastos legales), sin importar su causa, que puedan surgir como resultado del incumplimiento de cualquiera de las obligaciones derivadas del presente Contrato.
        </p>
        <p className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
          Al aceptar los presentes Términos y Condiciones de Uso manifiestas que:
        </p>
        <ul className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
          <li>
            Actúas bajo nombre propio.
          </li>
          <li>
            Estás de acuerdo en sujetarte a las leyes mexicanas en lo relacionado con el Contrato y los Servicios de La Gran Vida.
          </li>
          <li>
            Eres mayor de 18 años y/o cuentas con la edad legal para la contratación de los Servicios, de acuerdo con la legislación que rige tu nacionalidad.
          </li>
          <li>
            Te dedicas a actividades lícitas y no eres parte de actividades criminales o ilegales ni tienes la intención de utilizar tu Perfil de Usuario en relación con tales actividades.
          </li>
          <li>
            No utilizas o pretendes utilizar o permitir que otra persona utilice tu Perfil de Usuario para cualquier fin ilegal o prohibido, incluyendo, sin limitación, fraude o lavado de dinero, bajo las leyes de México y/o cualquier otra legislación aplicable a La Gran Vida.
          </li>
          <li>
            Comprendes que La Gran Vida no es, ni puede ser responsable del uso que le des a la información proporcionada.
          </li>
        </ul>
        <p className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
          En La Gran Vida nos reservamos el derecho de asumir la defensa y control de cualquier asunto o reclamo que implique o pudiera implicar el pago de una indemnización asociada con algún incumplimiento de tu parte, por lo que te comprometes a cooperar con La Gran Vida en el desarrollo de las defensas pertinentes.
        </p>

        <h1 className='app-anton-regular-font app-secondary-text'>
          19.DESCARGO Y LÍMITE DE LA RESPONSABILIDAD DE LA GRAN VIDA.
        </h1>         
        <p className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
          Reconoces y aceptas que La Gran Vida es únicamente una Plataforma de carácter informativo, y no proporciona dictámenes ni opiniones médicas, ya que no es ni puede considerarse como un servicio de atención de salud, ni un substituto de este o de recomendación y diagnósticos o tratamientos de médico profesionales, por lo anterior, de manera expresa e irrevocable liberas a La Gran Vida de cualquier obligación y/o responsabilidad respecto de tu atención física y mental, obligándote, en caso de requerirlo a atenderte y buscar un diagnóstico de un especialista de la salud debidamente acreditado.
        </p>        
        <p className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
          Reconoces que no te reservas, ni ejercerás ninguna acción de cualquier naturaleza, en contra de La Gran Vida, sus socios, accionistas, empleados, apoderados y colaboradores, empresas filiales y/o subsidiarias, o cualquier otro miembro de La Gran Vida, así como socios comerciales y proveedores; que pudiera derivar en forma directa o indirecta, de cualquier riesgo que puedas sufrir o te puedan ocurrir o a las personas que interactúen con la Plataforma, incluyendo, de manera enunciativa más no limitativa: autodiagnósticos, cualquier tipo de trastorno afectivo o mental o cualquier otro riesgo que se pudiera generar de la interacción entre tú y La Gran Vida
        </p>        
        <p className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
          La Gran Vida no otorga garantía expresa sobre la veracidad, puntualidad, idoneidad, fiabilidad, disponibilidad, oportunidad, seguridad o continuidad de la operación y funcionamiento de los Servicios y contenidos de la Plataforma, así como de la información, publicidad, mercadotecnia y promociones de Terceros, ni sobre la ausencia de virus u otros componentes dañinos, exactitud, utilidad de los Contenidos y/o Servicios de la Plataforma.
        </p>        
        <p className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
          La Plataforma puede contener dispositivos técnicos de enlace, tales como hipervínculos (links), anuncios, botones, imágenes, directorios y/o herramientas de búsqueda, entre otros, que te permitan acceder a otros Plataformas de internet sobre los que La Gran Vida no ejercemos ningún tipo de control o vigilancia, por lo que en caso de que accedas o utilices dichos links, estos serán bajo tu propia y exclusiva responsabilidad.
        </p>        
        <p className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
          <b className='app-secondary-text'>
            La información, conceptos y opiniones publicados en la Plataforma no reflejan la posición de La Gran Vida, ni de sus empleados, oficiales, directores, accionistas o licenciatarios. La Gran Vida no está a favor, ni en contra, de ningún movimiento o ideología política, deporte, religión o medicina alternativa. La Gran Vida no nos haremos responsables por ninguna de las informaciones, opiniones y conceptos que se emitan en la Plataforma. Si deseas obtener más información de un tema en específico proveído por La Gran Vida, sus Facilitadores o Patrocinadores, deberás consultarlo directamente con cada uno de ellos, según corresponda, y/o con un especialista en la materia.
          </b>
        </p>        
        <p className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
          Hasta el máximo permitido por las leyes aplicables, en La Gran Vida no seremos responsables, en ningún caso, por daños directos, especiales, incidentales, indirectos, o consecuenciales que en cualquier forma se deriven o se relacionen con:
        </p>
        <ul className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
          <li>
            El uso o ejecución de la Plataforma con el retraso o la falta de disponibilidad de uso de La Gran Vida.
          </li>
          <li>
            La proveeduría o falta de esta de Servicios de cualquier información o gráficos contenidos o publicados en o a través de la Plataforma.
          </li>
          <li>
            La actualización o falta de actualización de la información.
          </li>
          <li>
            La alteración o modificación, total o parcial, de la información después de haber sido incluida en la Plataforma.
          </li>
        </ul>
        <p className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
          Todos los supuestos anteriores serán vigentes, aun en los casos en que se le hubiere notificado o avisado a La Gran Vida acerca de la posibilidad de que se ocasionarán dichos daños.
        </p>

        <h1 className='app-anton-regular-font app-secondary-text'>
          20.RESPONSABILIDAD POR FACTORES EXTERNOS.
        </h1>   
        <p className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
          Para el debido ingreso a la Plataforma deberás contar con los equipos e instalaciones necesarias para tu conexión a Internet (computadora, teléfono, modem, etc.), siendo el uso de estos equipos, tu total responsabilidad.
        </p>
        <p className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
          Aceptas y reconoces que La Gran Vida está exento de cualquier responsabilidad que ocurra por interrupciones o suspensiones del servicio de acceso a Internet ocasionadas por la falla en el sistema de telecomunicaciones, en el suministro de energía eléctrica, casos fortuitos o de fuerza mayor o una acción de terceros que puedan inhabilitar los equipos que suministran el acceso a la red. En La Gran Vida no seremos responsables por cualquier virus que pudiera infectar tu equipo, como consecuencia del acceso y/o uso de la Plataforma o a raíz de cualquier transferencia de datos, archivos, imágenes, textos, o audios contenidos en el mismo. En La Gran Vida no garantizamos el acceso y uso continuado o ininterrumpido de la Plataforma.
        </p>
        <p className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
          Adicionalmente, La Gran Vida no es, ni será responsable por el uso de los Plataformas de Internet a los que puedas acceder a través de ligas contenidas en la Plataforma. Estas ligas y otros recursos a los que se hace referencia se proveen únicamente como servicio a los Usuarios de la "World Wide Web" y su inclusión en la Plataforma no constituye un endoso de, o afiliación a La Gran Vida.
        </p>
      
        <h1 className='app-anton-regular-font app-secondary-text'>
          21.VIOLACIONES.
        </h1>
        <p className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
          En La Gran Vida nos reservamos el derecho de tomar cualquier acción que consideremos apropiada, en caso de incumplimiento a las disposiciones señaladas en el Contrato, entre las que se incluyen, a nuestra entera discreción, a cancelar o suspender el acceso a alguno o a todos los Contenidos o Servicios de la Plataforma en cualquier momento, sin previo aviso, por iniciativa propia o a petición de terceros por cualquier motivo, incluyendo sin limitación a aquellos Usuarios que hagan uso indebido de la Plataforma.
        </p>
        <p className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
          Así mismo, en este acto, aceptas indemnizar a La Gran Vida, sus afiliadas, proveedores, empleados, vendedores y asesores de cualquier acción, demanda o reclamación (incluso de honorarios de abogados y de costas judiciales) derivadas de cualesquiera incumplimientos de tu parte al presente Contrato, incluyendo, sin limitación alguna de las derivadas:
        </p>
        <ul className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
          <li>
            De cualquier aspecto relativo al uso de la Plataforma.
          </li>
          <li>
            De injurias, difamación o cualquier otra conducta violatoria del presente Contrato.
          </li>
          <li>
            De violaciones a las leyes aplicables o tratados internacionales relativos a los derechos de autor o propiedad intelectual, contenidos o disponibles en, o a través de la Plataforma.
          </li>
        </ul>
        <p>
          En caso de que conozcas de cualquier violación al presente Contrato o que consideres que tus derechos han sido violados, te pedimos que nos lo hagas saber, para tratar de llegar a una solución.
        </p>

        <h1 className='app-anton-regular-font app-secondary-text'>
          22.PROPIEDAD INTELECTUAL.
        </h1>
        <p className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
          Al ingresar a la Plataforma reconoces que la Plataforma, sus logotipos y todo el material que en ella aparece, tales como marcas, nombres de dominio, nombres comerciales, obras artísticas, audio, video, información, imágenes y fotografías, son propiedad de sus respectivos titulares y están protegidos por los tratados internacionales y las leyes aplicables en materia de propiedad intelectual y derechos de autor, por lo que se prohíbe su uso total o parcial para fines no personales, incluyendo la prohibición de su uso para divulgación científica, de salud, informativa, noticiosa o de publicidad.
        </p>
        <p className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
          Los derechos de autor sobre el contenido, organización, recopilación, compilación, información, logotipos, fotografías, imágenes, programas, aplicaciones, y en general cualquier información contenida o publicada en la Plataforma se encuentran debidamente protegidos a favor de La Gran Vida, sus afiliados, proveedores y/o de sus respectivos propietarios, de conformidad con la legislación aplicable en materia de propiedad intelectual e industrial.
        </p>
        <p className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
          Se prohíbe expresamente copiar, reproducir, adaptar, modificar, alterar o suprimir, ya sea en forma total o parcial, los avisos, marcas, nombres comerciales, señas, anuncios, logotipos o en general cualquier indicación que se refiera a la propiedad de la información contenida en la Plataforma. En caso de que transmitas a La Gran Vida cualquier información, programas, aplicaciones, software o en general cualquier material que requiera ser licenciado a través de la Plataforma, otorgas con este acto a La Gran Vida una licencia perpetua, universal, gratuita, no exclusiva, mundial y libre de regalías, que incluye los derechos de sublicenciar, vender, reproducir, distribuir, transmitir, crear trabajos derivados, exhibirlos y ejecutarlos públicamente.
        </p>
        <p className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
          Lo establecido en el párrafo anterior se aplicará igualmente a cualquier otra información que envíes o transmitas a La Gran Vida, incluyendo, sin limitación alguna, preguntas, críticas, comentarios y sugerencias para renovar o mejorar la Plataforma, ya sea que estas hayan sido incluidas en cualquier espacio de la página señalada o en virtud de otros medios o modos de transmisión conocidos o que sean desarrollados en el futuro. Además, cuando remitas comentarios o críticas a la Plataforma, también estarás concediendo a La Gran Vida el derecho a utilizar tu nombre, en el marco de dicha revisión, comentario, o cualquier otro contenido.
        </p>
        <p className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
          Por lo anterior, renuncias expresamente con este acto a llevar a cabo cualquier acción, demanda o reclamación en contra de La Gran Vida, sus afiliados o proveedores por cualquier actual o eventual violación de cualquier derecho de autor o propiedad intelectual derivado de la información, programas, aplicaciones, software, ideas y demás material que envíes a la Plataforma.
        </p>
        <p className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
          En caso de considerar que cualquier Contenido publicado en la Plataforma es violatorio de derechos de propiedad intelectual o industrial, podrás realizar una notificación contactando a La Gran Vida a través del correo electrónico <b className='app-secondary-text'><a href="mailto:hola@lagranvida.com">hola@lagranvida.com</a></b>, indicando: 
        </p>
        <ul className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
          <li>
            Datos personales como nombre, dirección, número de teléfono y dirección de correo electrónico del reclamante;
          </li>
          <li>
            Firma autógrafa del titular de los derechos de propiedad intelectual;
          </li>
          <li>
            Indicación precisa y completa del (los) contenido(s) protegido(s) mediante los derechos de propiedad intelectual supuestamente infringidos, así como la localización de dichas violaciones en la Plataforma;
          </li>
          <li>
            Declaración expresa y clara de que la introducción del (los) contenido(s) indicado(s) se ha realizado sin el consentimiento del titular de los derechos de propiedad intelectual supuestamente infringidos; y
          </li>
          <li>
            Declaración expresa, clara y bajo la responsabilidad del reclamante de que la información proporcionada en la notificación es exacta y de que la introducción del(los) contenido(s) constituye una violación de dichos derechos.
          </li>
        </ul>

        <h1 className='app-anton-regular-font app-secondary-text'>
          23.MATERIAL PUBLICITARIO.
        </h1>
        <p className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
          Reconoces y aceptas que algunas partes de la Plataforma pueden contener información, imágenes, anuncios y demás material publicitario o promocional de Patrocinadores, quienes son responsables de asegurar que el Material Publicitario sometido para su inclusión en la Plataforma cumpla con las leyes y los códigos reguladores pertinentes. La Gran Vida no es, ni será responsable de ningún error o inexactitud en los Materiales de Publicidad.
        </p>
        <p className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
          Asimismo, reconoces y aceptas en este acto que dicho Material Publicitario se encuentra protegido por las leyes que en materia de propiedad intelectual e industrial resulten aplicables.
        </p>

        <h1 className='app-anton-regular-font app-secondary-text'>
          24.LICENCIA LIMITADA DE LA PLATAFORMA.
        </h1>
        <p className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
          La Gran Vida otorga en este acto al Usuario una licencia limitada, no exclusiva, revocable y sin derecho a sublicenciar, para que este último utilice la Plataforma de conformidad con el presente Contrato. La presente licencia estará en vigor hasta en tanto se mantenga la relación entre La Gran Vida y el Usuario, o bien, hasta que la misma sea cancelada por La Gran Vida. Para el caso en que de acuerdo con la legislación sea necesario establecer un plazo de vigencia de la licencia otorgada por La Gran Vida, la misma tendrá vigencia de 1 (un) año, renovable automáticamente por periodos de la misma duración hasta que la misma quede cancelada o bien cuando la relación entre La Gran Vida y el Usuario termine por cualquier circunstancia.
        </p>
        <p className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
          Para el caso de que La Gran Vida utilice algún tipo de software de terceros que se incluya en la Plataforma, el mismo se brinda al Usuario de conformidad con las licencias de uso, términos y condiciones de uso, así como restricciones establecidas por su titular.
        </p>
        <p className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
          La Gran Vida es responsable de operar, mantener, presentar la información y contenidos de la Plataforma; sin embargo, dichos contenidos pueden provenir no solo de La Gran Vida sino de sitios de Terceros o de los Facilitadores, por lo que, La Gran Vida es únicamente responsable del funcionamiento y contenidos propios. Respecto de contenidos de Terceros, su responsabilidad se limita a presentar los contenidos “tal cual” le son proveídos.
        </p>
        <p className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
          El Usuario únicamente podrá imprimir y/o copiar cualquier información y/o imagen contenida o publicada en la Plataforma para uso personal, por lo que queda expresa y terminantemente prohibido el uso comercial de dicha información. La presente licencia estará en vigor hasta en tanto se mantenga la relación entre tú y La Gran Vida.
        </p>
        <p className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
          La reimpresión, publicación, distribución, asignación, sublicencia, venta, reproducción electrónica o por otro medio, parcial o total, de cualquier información, imagen, documento o gráfico que aparezca en la Plataforma para cualquier uso distinto al personal no comercial está expresamente prohibido, a menos que cuentes con la autorización previa y por escrito por parte de La Gran Vida. Cualquier infracción de estos Términos y Condiciones de Uso dará lugar a la revocación inmediata de la licencia otorgada en este apartado, sin previo aviso.
        </p>

        <h1 className='app-anton-regular-font app-secondary-text'>
          25.CONFIDENCIALIDAD.
        </h1>
        <p className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
          La Gran Vida se esfuerza en mantener la confidencialidad de la información que recibamos y que tenga dicho carácter, conforme a las disposiciones legales aplicables en México. Trataremos tu información conforme al Aviso de Privacidad correspondiente. La Gran Vida no asumimos ninguna obligación respecto de cualquier otra información que no sea considerada un dato personal.
        </p>
        <p className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
          Toda información divulgada por La Gran Vida, sus filiales y/o subsidiarias, de forma verbal, electrónica, visual, por escrito o en cualquier otra forma tangible, incluyendo sin limitar las ideas, fórmulas, bases de datos, normas, artículos, estudios, folletos, publicaciones, manuales, sistemas, procedimientos, informes, reportes técnicos, minutas, know-how, secretos industriales, patentes, derechos de autor, software, licencias, datos, invenciones, algoritmos, técnicas, procesos, planes y proyectos de mercadotecnia, publicidad, estrategias, pronósticos, información confidencial de terceros, listas de clientes, contactos y/o proveedores, planes de negocios, análisis de mercado, conocimiento de mercado o cualquier otra información técnica, económica, relativa o relacionada con las operaciones, desarrollo de negocios, desarrollo de productos, desarrollo de nuevos proyectos, finanzas, mercadotecnia, publicidad, promociones comerciales, logística, operaciones de canje promocionales o toda aquella información cuya divulgación y exposición, negocios o de cualquier otra índole, es y seguirá siendo, en todo momento, propiedad de La Gran Vida, por lo cual reconoces que no adquieres ningún tipo de derecho, licencia, propiedad o interés respecto de la misma, para copiarla, venderla, usarla o disponer de ella de forma alguna, limitándose expresa y exclusivamente a usarla para los fines indicados por La Gran Vida.
        </p>
        <p className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
          Al usar la Plataforma te obligas a no apoderarte, usar o explotar, por sí o por terceros, directa o indirectamente, para beneficio propio o de terceros y a no divulgar o revelar a terceros la Información Confidencial, a la cual haya tenido, tenga o vaya a tener acceso en el futuro. Asimismo, te obligas a tomar todas las medidas razonables para evitar cualquier difusión prohibida y/o uso indebido de la Información Confidencial.
        </p>
        <p className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
          Al utilizar nuestros Servicios, aceptas que La Gran Vida cuenta con la facultad de comunicarse contigo por correo electrónico o notificaciones push (una notificación push o automática es un mensaje corto que aparece como una ventana emergente en el navegador de su escritorio, pantalla de inicio del móvil o en el centro de notificaciones de su dispositivo desde una aplicación móvil). Así como para enviar cualquier tipo de información lícita que pueda ser de interés, incluyendo publicidad e información sobre ofertas y promociones, en caso de así requerirlo.
        </p>

        <h1 className='app-anton-regular-font app-secondary-text'>
          26.NO ASOCIACIÓN.
        </h1>
        <p className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
          El uso de la Plataforma y/o su Contenido no crea ninguna asociación, fiduciario profesional o de otro tipo, por lo que, al utilizar la Plataforma, aceptas los presentes Términos y Condiciones de Uso y aceptas que no existe ningún tipo de asociación, sociedad, relación laboral, subordinación, agencia, comisión o cualquier similar con La Gran Vida, por virtud del presente Contrato.
        </p>

        <h1 className='app-anton-regular-font app-secondary-text'>
          27.CESIÓN DE DERECHOS.
        </h1>        
        <p className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
          En La Gran Vida podremos, en cualquier tiempo y cuando así lo estimemos conveniente, ceder total o parcialmente nuestros derechos y obligaciones derivados del presente Contrato. En virtud de dicha cesión, La Gran Vida quedará liberada de cualquier obligación establecida en el presente Contrato.
        </p>

        <h1 className='app-anton-regular-font app-secondary-text'>
          28.DISPOSICIONES INEFICACES.
        </h1>   
        <p className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
          En caso de que alguna cláusula o parte de esta sea considerada no ejecutable o se declare nula o inválida de acuerdo con la legislación aplicable, será sustituida por un término o condición que sea válido y que pueda cumplir con el objetivo de la cláusula o parte no ejecutable o que haya sido declarada nula o inválida. Los demás Términos y Condiciones de Uso continuarán en plena vigencia. Cualquier derecho que no se haya conferido expresamente en este documento se entiende reservado a La Gran Vida. 
        </p>

        <h1 className='app-anton-regular-font app-secondary-text'>
          29.TÉRMINOS ADICIONALES.
        </h1>   
        <p className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
          Ocasionalmente, en La Gran Vida podremos agregar Términos y Condiciones de Uso específicos para los diferentes Cursos, Programas y Servicios ofertados en la Plataforma, los cuales serán publicados en las áreas específicas para su lectura y aceptación. Por lo anterior, reconoces y aceptas que los Términos y Condiciones de Uso adicionales forman parte integrante del presente Contrato para todos los efectos legales a que haya lugar.
        </p>

        <h1 className='app-anton-regular-font app-secondary-text'>
          30.NO RENUNCIA DE DERECHOS.
        </h1>
        <p className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
          La inactividad por parte de La Gran Vida, sus afiliados o proveedores al ejercicio de cualquier derecho o acción derivados del presente convenio, en ningún momento deberá interpretarse como renuncia a dichos derechos o acciones.
        </p>

        <h1 className='app-anton-regular-font app-secondary-text'>
          31.VIGENCIA.
        </h1>
        <p className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
          Reconoces que los Términos y Condiciones de Uso son de vigencia ilimitada, y entrarán en vigor a partir de su publicación en la Plataforma.
        </p>
        <p className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
          En La Gran Vida nos reservamos el derecho de efectuar alteraciones al presente documento sin necesidad de previo aviso, por lo anterior, La Gran Vida te recomienda que leas con regularidad este documento, de forma que te mantengas siempre informado sobre eventuales modificaciones. Cualquier modificación al presente Contrato se volverá efectiva inmediatamente después de su publicación en la Plataforma.
        </p>
        <p className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
          Una vez realizadas las modificaciones, en caso de que continúes usando la Plataforma, se presumirá que tienes pleno conocimiento, has leído y consentido los Términos y Condiciones de Uso reformados. En caso de que no aceptes los Términos y Condiciones de Uso modificados deberás dejar de utilizar la Plataforma.
        </p>
        <p className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
          En La Gran Vida podremos en cualquier momento suspender el acceso a la Plataforma y/o terminar los presentes Términos y Condiciones de Uso. La terminación de los presentes Términos y Condiciones de Uso no implicará en ningún caso que La Gran Vida debe de indemnizarte.
        </p>

        <h1 className='app-anton-regular-font app-secondary-text'>
          32.TÍTULOS DE LOS APARTADOS.
        </h1>
        <p className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
          Tanto La Gran Vida como tú, acordamos que los títulos que se han asignado a cada uno de los apartados que constan en el presente documento, se establecen únicamente para conveniencia de estos, y que los mismos, no deberán ser considerados en la interpretación del presente Contrato.
        </p>

        <h1 className='app-anton-regular-font app-secondary-text'>
          33.FUERZA MAYOR.
        </h1>
        <p className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
          El acceso a la Plataforma puede suspenderse por razones de tipo técnico o por causas de fuerza mayor, entendiendo esta última como cualquier acontecimiento o circunstancia que no haya podido ser previsto por La Gran Vida o que, previendo, haya sido inevitable e influya directamente en el cumplimiento del Contrato, de forma que la demora no pueda ser atendida con otras medidas, o que impida de algún modo el cumplimiento de las obligaciones establecidas, incluyendo sin limitar, fuego, inundaciones, terremotos, actos de la naturaleza, actos de guerra, terrorismo, tormentas eléctricas, levantamientos civiles, rebeliones, revoluciones, epidemias, emergencias sanitarias, presencia generalizada de patógenos, órdenes administrativas emitidas por autoridades competentes, restricciones tecnológicas generalizadas, hackeos o ataques tecnológicos, pérdida generalizada de internet y/o cualesquiera otros que puedan aplicar o que hayan sido determinados de conformidad con la normatividad aplicable o la doctrina legal generalmente aceptada.
        </p>
        <p className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
          Estas causas que se expresan a título enunciativo más no limitativo tienen carácter de imprevisibles o que, previstas o previsibles, son inevitables, por lo que La Gran Vida queda exonerada de cualquier tipo de responsabilidad al respecto.
        </p>

        <h1 className='app-anton-regular-font app-secondary-text'>
          34.COMENTARIOS, QUEJAS Y SUGERENCIAS.
        </h1>
        <p className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
          Para cualquier comentario o sugerencia, ponemos a tu disposición la siguiente dirección de correo electrónico: <b className='app-secondary-text'><a href="mailto:hola@lagranvida.com">hola@lagranvida.com</a></b>.
        </p>
        <p className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
          Cualquier comentario, sugerencia, propuesta, estudio, oferta o en general cualquier tipo de información que envíes por medio de los correos electrónicos, teléfonos y/o cualquier otro medio de comunicación, no implicará la aceptación de La Gran Vida a ningún tipo de compromiso u obligación.
        </p>

        <h1 className='app-anton-regular-font app-secondary-text'>
          35.JURISDICCIÓN APLICABLE.
        </h1>
        <p className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
          Reconoces y aceptas que el presente Contrato está regulado conforme a las leyes de México, por lo que, sin importar tu ubicación o nacionalidad, en este acto te obligas a resolver cualquier disputa o interpretación en los tribunales mexicanos y con apego a leyes vigentes aplicables de la Ciudad de Monterrey NL,  renunciando a cualquier otra jurisdicción derivada de tu nacionalidad o domicilio presentes o futuros.
        </p>
        <p className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
          Por lo anterior, reconoces y aceptas que el presente Contrato se celebrará de manera electrónica, el cual será vinculante, y por lo que el presente Contrato constituye un acuerdo de voluntades y vinculante para ambas partes.
        </p>

        <h1 className='app-anton-regular-font app-secondary-text text-center'>
          Fecha de actualización: 16/08/2024
        </h1>
        <p className='app-montserrat-medium-font app-neutral-grey-1-text fs-4 text-center'>
          <i>
            La Gran Vida® es una marca registrada, por lo anterior, queda prohibida la reproducción total o parcial, por cualquier medio o forma, sin la autorización previa, expresa y por escrito de su titular.
          </i>
        </p>

      </Container>
    </>
  );

  // #endregion

  return (
    <>
      <article className='app-quaternary-bg'>
        <section>
          {detailsTemplate}
        </section>
      </article>
    </>
  );
}

export default TermsAndConditionsDetailsBox;