import { Button, Col, Container, Row } from 'react-bootstrap';
import { faQuoteLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Accordion from 'react-bootstrap/Accordion';
import QuoteBox from '../../../common/QuoteBox/QuoteBox';
import * as Paths from "../../../../constants/paths";

function DetailsBox() {

  // #region Templates

  const faqTemplate = (question, answer) => {

  };

  const detailsTemplate = (
    <>
      <Container className='p-5'>   
        <Accordion alwaysOpen className='pt-5'>
          <Accordion.Item eventKey="1">
            <Accordion.Header>
              <h4 className='app-montserrat-medium-font app-primary-text'>
                1. ¿Cómo me suscribo?
              </h4>
            </Accordion.Header>
            <Accordion.Body>
              <p className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
                A través del icono de LOGIN o en la sección de contacto.
              </p>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="2">
            <Accordion.Header>
              <h4 className='app-montserrat-medium-font app-primary-text'>
                2. ¿Qué beneficios me da estar suscrito a su “news letter”?
              </h4>
            </Accordion.Header>
            <Accordion.Body>
              <p className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
                Recibirás noticias de salud mental, enlaces a blogs, ofertas y promociones exclusivas, oportunidades de participar en nuevos talleres y novedades de nuestra comunidad.
              </p>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="3">
            <Accordion.Header>
              <h4 className='app-montserrat-medium-font app-primary-text'>
                3. ¿Qué hacen en LAGV? 
              </h4>
            </Accordion.Header>
            <Accordion.Body>
              <p className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
                Promovemos el bienestar y el desarrollo personal a través de ofrecer cursos, talleres y programas que ayuden a quienes los tomas a buscar y encontrar su visión de futuro que les represente a cada uno LA GRAN VIDA que desean.
              </p>
              <p className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
                Así mismo LAGV es una plataforma para que muchos terapeutas puedan ofrecer sus talleres, programas y cursos.
              </p>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="4">
            <Accordion.Header>
              <h4 className='app-montserrat-medium-font app-primary-text'>
                4. ¿Qué es 40 días para una Gran Vida?
              </h4>
            </Accordion.Header>
            <Accordion.Body>
              <p className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
                Es una metodología de desarrollo personal, con herramientas de diferentes corrientes que ofrece la posibilidad del crecimiento en las personas que desean reorientar su camino en la vida, que desean hacer cambios orientados al bienestar y desarrollo. Está compuesto de un componente base y seguido de varios componentes que profundizan en temas particulares. 
              </p>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="5">
            <Accordion.Header>
              <h4 className='app-montserrat-medium-font app-primary-text'>
                5. ¿Cómo puedo tomar cursos en línea? 
              </h4>
            </Accordion.Header>
            <Accordion.Body>
              <p className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
                Solo debes suscribirte e inscribirte a cualquier curso disponible en nuestro menú. Es muy sencillo, da click, registrate y ¡listo!.
              </p>
            </Accordion.Body>
          </Accordion.Item>    
          <Accordion.Item eventKey="6">
            <Accordion.Header>
              <h4 className='app-montserrat-medium-font app-primary-text'>
                6. ¿Cómo puedo asistir a talleres presenciales? 
              </h4>
            </Accordion.Header>
            <Accordion.Body>
              <p className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
                En nuestro menú de talleres, revisa la oferta, cuidad y fechas, haz “click” en inscribirte y llena el formulario. ¡Nos encantará verte en persona! 
              </p>
            </Accordion.Body>
          </Accordion.Item>   
          <Accordion.Item eventKey="7">
            <Accordion.Header>
              <h4 className='app-montserrat-medium-font app-primary-text'>
                7. ¿Qué hago si necesito un apoyo presencial? 
              </h4>
            </Accordion.Header>
            <Accordion.Body>
              <p className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
                Puedes entrar a la sección de sesiones personales y agendar desde ahí. También puedes acceder a nuestro directorio de terapeutas para que escojas aquel que mejor se acomode a tu necesidad y ubicación.
              </p>
            </Accordion.Body>
          </Accordion.Item>   
          <Accordion.Item eventKey="8">
            <Accordion.Header>
              <h4 className='app-montserrat-medium-font app-primary-text'>
                8. ¿Cómo sé si necesito apoyo terapéutico? 
              </h4>
            </Accordion.Header>
            <Accordion.Body>              
              <p className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
                Si estas pasando por un momento crítico en tu vida como: rupturas en relaciones, duelos, ansiedad excesiva, falta de claridad en tu camino futuro, sensación de desgano hacia la vida, comportamientos depresivos y no has encontrado en familiares y amigos la forma de salir de ellos, te aconsejamos buscar ayuda profesional.
              </p>
              <p className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
                En La Gran Vida NO pretendemos sustituir el apoyo y soporte de un proceso terapéutico.
              </p>
              <p className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
                Visita nuestro directorio de terapeutas, donde encontrarás la recomendación de algunos expertos que podrían acompañarte en este proceso de vida. 
              </p>
            </Accordion.Body>
          </Accordion.Item>  
          <Accordion.Item eventKey="9">
            <Accordion.Header>
              <h4 className='app-montserrat-medium-font app-primary-text'>
                9. ¿Qué es LAGV? 
              </h4>
            </Accordion.Header>
            <Accordion.Body>
              <p className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
                Es una plataforma de formación en línea, con herramientas de diferentes corrientes.
              </p>
              <p className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
                Que busca el crecimiento en las personas que desean mejorar sus condiciones de vida a través de una oferta de formación que le permite al usuario acceder a contenido de autodesarrollo.
              </p>
              <p className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
                También es una plataforma para que muchos terapeutas puedan ofrecer sus talleres, programas y cursos.
              </p>
            </Accordion.Body>
          </Accordion.Item>          
          <Accordion.Item eventKey="10">
            <Accordion.Header>
              <h4 className='app-montserrat-medium-font app-primary-text'>
                10. ¿Quién es LAGV? 
              </h4>
            </Accordion.Header>
            <Accordion.Body>
              <p className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
                Somos un grupo de terapeutas profesionales con muchos años de experiencia en acompañamiento de procesos personales, que busca compartir conocimiento alineado a los siguientes principios:
              </p>
              <ul>
                <li>
                  <p className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
                    <b>
                      Vivimos el Respeto
                    </b>,&nbsp;
                    Valoramos y validamos en nosotros mismos y en los demás sus capacidades, puntos de vista y elecciones desde el entendimiento que el mundo en el que vivo es mi creación.
                  </p>
                </li>
                <li>
                  <p className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
                    <b>
                      Generamos Relaciones valiosas
                    </b>,&nbsp;
                    las  mantenemos y somos recíprocos en relaciones que aportan a nuestra vida desde el amor y respeto.
                  </p>
                </li>
                <li>
                  <p className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
                    <b>
                      La Responsabilidad es nuestra guía en las elecciones que realizamos
                    </b>.&nbsp;
                    Vivimos la  responsabilidad afectiva, en mis puntos de vista y las consecuencias que derivan de mis actos.
                  </p>
                </li>
                <li>
                  <p className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
                    <b>
                      Amamos la vida
                    </b>,&nbsp;
                    Aceptamos la vida tal como es y a nosotros mismo como somos hoy. Nos damos  cuenta de que está en mí, lograr la vida que deseo.
                  </p>
                </li>
                <li>
                  <p className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
                    <b>
                      Somos Accesibles y buscamos que nuestra contribución sea de accesible para todos
                    </b>&nbsp;
                    aquellos que desean para si mismos una transformación positiva y amorosa en su realidad. Compartimos y colaboramos.
                  </p>
                </li>
              </ul>                          
            </Accordion.Body>
          </Accordion.Item>   
          <Accordion.Item eventKey="11">
            <Accordion.Header>
              <h4 className='app-montserrat-medium-font app-primary-text'>
                11. Soy terapeuta, ¿Cómo puedo compartir mi conocimiento a través de lagranvida.com? 
              </h4>
            </Accordion.Header>
            <Accordion.Body>
              <p className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
                Si eres un terapeuta o profesional con una idea o material que desees compartir y multiplicar su impacto e ingresos a través de nuestro sitio LA GRAN VIDA, haznos llegar tu información de contacto dando click al formulario en el apartado  “¿Deseas compartir tu ¿Programa o Taller?”.
              </p>
            </Accordion.Body>
          </Accordion.Item>   
          <Accordion.Item eventKey="12">
            <Accordion.Header>
              <h4 className='app-montserrat-medium-font app-primary-text'>
                12. ¿Cómo puede ayudarme a mi lagranvida.com? 
              </h4>
            </Accordion.Header>
            <Accordion.Body>
              <p className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
                La oferta que presentamos en nuestra plataforma tiene múltiples beneficios para aquellos que desean realizar cambios en su vida orientados a su bienestar y crecimiento personal en todos los ámbitos de su vida.
              </p>
            </Accordion.Body>
          </Accordion.Item>   
          <Accordion.Item eventKey="13">
            <Accordion.Header>
              <h4 className='app-montserrat-medium-font app-primary-text'>
                13. ¿Qué pasa si no puedo concluir un programa?
              </h4>
            </Accordion.Header>
            <Accordion.Body>
              <p className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
                Una vez que adquieres un programa dentro de la plataforma, permanecerá activo por el tiempo estipulado en el mismo, normalmente 24 meses para que lo concluyas.
              </p>
            </Accordion.Body>
          </Accordion.Item>   
          <Accordion.Item eventKey="14">
            <Accordion.Header>
              <h4 className='app-montserrat-medium-font app-primary-text'>
                14. ¿Cómo puedo tomar sesiones terapéuticas?
              </h4>
            </Accordion.Header>
            <Accordion.Body>
              <p className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
                Puedes entrar a la sección de sesiones personales y agendar desde ahí. También puedes acceder a nuestro directorio de terapeutas para que escojas aquel que mejor se acomode a tu necesidad y ubicación.
              </p>
            </Accordion.Body>
          </Accordion.Item>           
        </Accordion>      
      </Container>
    </>
  );

  // #endregion

  return (
    <>
      <article className='app-quaternary-bg'>
        <section>
          {detailsTemplate}
        </section>
      </article>
    </>
  );
}

export default DetailsBox;