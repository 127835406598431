import { useEffect } from 'react';
import BlogDetailsBox from './BlogDetailsBox/BlogDetailsBox';
import EpilogueBox from './EpilogueBox/EpilogueBox';
import HeroBannerBox from './HeroBannerBox/HeroBannerBox';

function WhatsInTheBookBlogView() {

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
  }, []);

  return (
    <>
      <article className='app-has-navbar'>
        <section>
          <HeroBannerBox />
        </section>
        <section>
          <BlogDetailsBox />
        </section>
        <section>
          <EpilogueBox />
        </section>
      </article>
    </>
  );
}

export default WhatsInTheBookBlogView;