import { faEnvelope, faKey } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as formik from 'formik';
import React, { useState } from 'react';
import { Alert } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import { useNav } from '../../../hooks/useNav';
import * as Paths from "../../../constants/paths";
import * as yup from 'yup';
import { useAuth } from '../../../hooks/useAuth';
import BrandLogo from '../../layouts/common/BrandLogo/BrandLogo';

function SignInView() {

  // #region vars and consts

  const { login } = useAuth();
  const [alertWarningIsVisible, setAlertWarningIsVisible] = useState(false);
  const nav = useNav();
  const { Formik } = formik;
  const [signInData, setSignInData] = useState({
    username: '',
    password: ''
  });
  const validationSchema = yup.object().shape({
    username: yup.string().required(),
    password: yup.string().required()
  });

  // #endregion

  // #region Events

  const onSubmit = (data, form) => {
    resetAlerts();
    signIn(data.username, data.password);
  };

  // #endregion

  // #region Methods

  const signIn = (
    username,
    pass) => {
    fetch(`${process.env.REACT_APP_API_URL}/lagranvida/v1/user/login`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        username,
        pass
      }),
    }).then(response => response.json()).then(data => {
      if (data.responseCode === 'OK') {
        if (data.responseObject.userId === 0) {
          console.log("Error", data);
          setAlertWarningIsVisible(true);
        } else {
          const user = data.responseObject;
          const pages = user.pages;
          login(user);
          nav.navigate(Paths.views.USER_HOME);
        }
      }
    });
  }

  const resetAlerts = () => {
    setAlertWarningIsVisible(false);
  };

  // #endregion

  // #region Templates

  const signInTemplate = (
    <>
      {/* <video className='app-sign-in-video-bg' autoPlay muted loop>
          <source src={video} type="video/mp4" />
            Your browser does not support HTML5 video.
        </video> */}
      <div className='row p-4'>
        <div className='col-12 offset-sm-1 col-sm-10 offset-md-2 col-md-8 offset-lg-3 col-lg-6 offset-xl-4 col-xl-4'>
          <div className='row'>
            <div className='col-12'>
              <BrandLogo />  
            </div>
          </div>
          <div className='row'>
            <div className='col-12'>
              <h1 className='app-neutral-white-text app-montserrat-regular-font text-uppercase text-center'>
                Inicio de sesión
              </h1>
            </div>
          </div>
          <div className='row'>
            <div className='col-12'>
              <Alert
                key='warning'
                variant='warning'
                hidden={!alertWarningIsVisible}>
                El usuario no existe
              </Alert>
            </div>
          </div>
          <div className='row'>
            <div className='col-12'>
              <Formik
                validationSchema={validationSchema}
                onSubmit={onSubmit}
                initialValues={signInData}
              >
                {({ handleSubmit, handleChange, values, touched, errors }) => (
                  <Form noValidate onSubmit={handleSubmit}>
                    <Row className="mb-3">
                      <Col>
                        <Form.Group as={Col} sm="12" controlId="validationFormikUsername">
                          <Form.Label>Username</Form.Label>
                          <InputGroup hasValidation>
                            <InputGroup.Text id="inputGroupPrepend">
                              <FontAwesomeIcon icon={faEnvelope} />
                            </InputGroup.Text>
                            <Form.Control
                              type="text"
                              placeholder="Username"
                              aria-describedby="inputGroupPrepend"
                              name="username"
                              value={values.username}
                              onChange={handleChange}
                              isInvalid={!!errors.username}
                            />
                            <Form.Control.Feedback type="invalid">
                              {errors.username}
                            </Form.Control.Feedback>
                          </InputGroup>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col>
                        <Form.Group as={Col} sm="12" controlId="validationFormikPassword">
                          <Form.Label>Password</Form.Label>
                          <InputGroup hasValidation>
                            <InputGroup.Text id="inputGroupPrepend">
                              <FontAwesomeIcon icon={faKey} />
                            </InputGroup.Text>
                            <Form.Control
                              type="password"
                              placeholder="Password"
                              aria-describedby="inputGroupPrepend"
                              name="password"
                              value={values.password}
                              onChange={handleChange}
                              isInvalid={!!errors.password}
                            />
                            <Form.Control.Feedback type="invalid">
                              {errors.password}
                            </Form.Control.Feedback>
                          </InputGroup>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Button type="submit" className='app-accent-button'>
                      Iniciar sesión
                    </Button>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </>
  );

  // #endregion

  return (
    <>
      <section className='app-sign-in app-primary-bg app-has-navbar'>
        {signInTemplate}
      </section>      
    </>
  );
}

export default SignInView;