import React from "react";
import Dropdown from 'react-bootstrap/Dropdown';
import * as Paths from "../../../../constants/paths";
import { useAuth } from "../../../../hooks/useAuth";
import { useNav } from "../../../../hooks/useNav";
import './Sidebar.scss';

const Sidebar = () => {
  const nav = useNav();
  const { hasPermission, user } = useAuth();

  const sidebarTemplate = (
    <>
      <nav className="app-sidebar">
        <Dropdown.Menu show>  
          {/* 
          {
            (
              hasPermission(PROFILE.PERMISSIONS.DUMMY_PERMISSION) 
            ) && (
              <>
                <Dropdown.Header>
                  <small>
                    DUMMY MENU
                  </small>
                </Dropdown.Header>
              </>
            )
          }            
          {
            hasPermission(PROFILE.PERMISSIONS.DUMMY_PERMISSION) && (
              <>
                <Dropdown.Item
                  eventKey="Dummy"
                  className="ps-4"
                  onClick={() => navigate("dummy")}>
                  Dummy Submenu
                </Dropdown.Item>                        
              </>
            )
          }
          */}
          <Dropdown.Header>
            <small>
              INICIO
            </small>
          </Dropdown.Header>
          <Dropdown.Item
            eventKey="userHome"
            className="ps-4"
            onClick={() => nav.navigate(Paths.views.USER_HOME)}>
            Inicio
          </Dropdown.Item>          
        </Dropdown.Menu>
      </nav>
    </>
  );

  return (
    <> 
      {
        user && sidebarTemplate
      }      
    </>
  )
}

export default Sidebar;