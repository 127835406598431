import { useEffect } from 'react';

function UserHomeView() {
  useEffect(() => {    
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
  }, []);
  
  return (
    <>
      <section className='app-has-navbar'>

      </section>
    </>
  );
}

export default UserHomeView;
