export const views = {
  aboutUs: {
    ABOUT_US: '/Nosotros',  
    FAQS: '/FAQs'
  },  
  blogs: {
    WHATS_IN_THE_BOOK_OF: "/blogs/QueHayEnElLibroDe",
    RECOMMENDED_PODCASTS: "/blogs/RecommendedPodcasts",
    WHAT_CAN_I_SEE_IN_THE_MOVIE_OF: "/blogs/QuePuedoVerEnLaPeliculaDe"  
  },
  HOME: "/",
  LOGIN: "/InicioDeSesion",
  PRIVACY_POLICY: "/AvisoDePrivacidad",
  programs: {
    FOURTY_DAYS_FOR_AN_AMAZING_LIFE: "/programs/40DiasParaUnaGranVida",
    ABUNDANCE: "/programs/Abundancia",
    LOVE: "/programs/Amor",
    WELLNESS: "/programs/Bienestar",
    JOY: "/programs/Gozo"   
  },
  SIGN_UP: "/Registro",
  TERMS_AND_CONDITIONS: "/TerminosYCondiciones",
  USER_HOME: "/userHome"
}

export const sections = {
  aboutUs: {
    WHO_ARE_WE: 'whoAreWeSection',
    WHAT_DEFINE_US: 'whatDefineUsSection',
    WHAT_DO_WE_DO: 'whatDoWeDoSection',
    WHY_DO_WE_HELP: 'whyDoWeHelpSection',
    HOW_DO_WE_HELP: 'howDoWeHelpSection',
    DO_YOU_WANT_TO_SHARE_YOUR_PROGRAM_OR_WORKSHOP: 'doYouWantToShareYourProgramOrWorkshopSection'
  },
  home: {
    TESTIMONIALS: 'testimonialsSection',
    BLOGS: 'blogsSection',
    PROGRAMS: 'programsSection',
    SPECIAL_EVENTS: 'specialEventsSection',        
  }
}