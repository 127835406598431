import { useEffect } from 'react';
import HeroBannerBox from './HeroBannerBox/HeroBannerBox';
import DetailsBox from './DetailsBox/DetailsBox';

function FAQsView() {

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
  }, []);

  return (
    <>
      <article className='app-has-navbar'>
        <section>
          <HeroBannerBox />
        </section>
        <section>
          <DetailsBox />
        </section>
      </article>
    </>
  );
}

export default FAQsView;