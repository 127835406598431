import { Button, Col, Container, Row } from 'react-bootstrap';
import programLoveImage from '../../../../assets/images/programs/la-gran-vida-program-amor-internal.png';
import programLoveTitleImage from '../../../../assets/images/programs/la-gran-vida-program-amor-title.png';
import program40DaysTitleImage from '../../../../assets/images/programs/la-gran-vida-program-40-dias-para-una-gran-vida-title.png';
import underlineWhiteIamge from '../../../../assets/images/underlines/underline-white.png';

function HeroBannerBox() {

  // #region Templates
  
  const heroBannerTemplate = (
    <>
      <Container
        fluid
        className='app-danger-bg py-5'
      >
        <Row>
          <Col
            xs={12}
            md={5}
            lg={{ span: 3, offset: 2 }}
            className='text-right position-relative d-none d-md-block'
          >
            <img
              className='w-75 position-absolute start-50 translate-middle shadow'
              src={programLoveImage}
              style={{
                borderRadius: '2rem',
                top: '70%'
              }}
            />
          </Col>
          <Col
            xs={12}
            md={7}
            lg={5}
          >
            <Row>
              <Col>
                <img
                  className='w-25 pb-2'
                  src={program40DaysTitleImage}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <img
                  className='w-75'
                  src={programLoveTitleImage}
                />
              </Col>
            </Row>
            <Row>
              <Col className='position-relative'>
                <h2
                  className='app-quaternary-text app-suomi-hand-script-font position-relative'
                  style={{
                    fontSize: '3rem'
                  }}
                >
                  Pilar para la gran vida
                </h2>
                <img
                  className='w-50 position-absolute'
                  src={underlineWhiteIamge}
                  style={{
                    left: '15px',
                    bottom: '-20px'
                  }}
                />
              </Col>
            </Row>
          </Col>
          <Col
            xs={12}
            className='text-right d-block d-md-none'
          >
            <img
              className='w-100 shadow p-4'
              src={programLoveImage}
              style={{
                borderRadius: '5rem'
              }}
            />
          </Col>
        </Row>
      </Container>
    </>
  );

  // #endregion

  return (
    <>
      <article>
        <section>
          {heroBannerTemplate}
        </section>
      </article>
    </>
  );
}

export default HeroBannerBox;