import { useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import primaryAbstractBackgroundImage from '../../../assets/images/backgrounds/la-gran-vida-primary-abstract-bg.png';
import Ad1Box from './Ad1Box/Ad1Box';
import Ad2Box from './Ad2Box/Ad2Box';
import BlogsBox from './BlogsBox/BlogsBox';
import GreatMindsBox from './GreatMindsBox/GreatMindsBox';
import HeroBannerBox from './HeroBannerBox/HeroBannerBox';
import ProgramsBox from './ProgramsBox/ProgramsBox';
import SpecialEventsBox from './SpecialEventsBox/SpecialEventsBox';
import TestimonialsBox from './TestimonialsBox/TestimonialsBox';
import * as Paths from "../../../constants/paths";

function HomeView() {

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
  }, []);
  
  return (
    <>
      <article className='app-has-navbar'>
        <section>
          <HeroBannerBox />
        </section>
        <section id={Paths.sections.home.PROGRAMS}>
          <ProgramsBox />
        </section>
        <section>
          <Row>
            <Col
              id={Paths.sections.home.TESTIMONIALS}
              lg={{ span: 6, offset: 6 }}
              xl={{ span: 9, offset: 3 }}
            >
              <TestimonialsBox />
            </Col>
          </Row>  
          <Row className='app-quaternary-bg'>
            <Col
              className='position-relative'
              lg={6}
              xl={3}
            >
              <Ad1Box />
            </Col>
            <Col
              id={Paths.sections.home.BLOGS}
              lg={6}
              xl={9}              
            >
              <BlogsBox />
            </Col>
          </Row>  
        </section>
        <section id={Paths.sections.home.SPECIAL_EVENTS}>
          <SpecialEventsBox />
        </section>
        <section>
          <Row
            className='align-items-end'
            style={{
              background: `url(${primaryAbstractBackgroundImage})`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover'
            }}
          >
            <Col lg={6} xl={4}>
              <Ad2Box />
            </Col>
            <Col lg={6} xl={8}>
              <GreatMindsBox />
            </Col>
          </Row>
        </section>
      </article>
    </>
  );
}

export default HomeView;