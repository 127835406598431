import { Button, Col, Container, Row } from 'react-bootstrap';

function ProgramDetailsBox() {

  // #region Templates

  const detailsTemplate = (
    <>
      <Container className='p-5'>
        <h1
          className='app-suomi-hand-script-font app-primary-text pb-3 pt-5'
          style={{
            fontSize: '3rem'
          }}
        >
          Gozo ¿De qué trata?
        </h1>   
        <p className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
          Este pilar representa la posibilidad experimentar la alegría como emoción y la felicidad como sentimiento para caminar la vida.
        </p>
        <p className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
          Recorreremos el camino de la felicidad, reconociéndola como esto: un camino y no un destino.
        </p>
        <p className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
          Aprende técnicas sencillas y probadas para lograr tener una experiencia enfocada en gozar de LA GRAN VIDA, tal como es.
        </p>
        <h1
          className='app-suomi-hand-script-font app-primary-text py-3'
          style={{
            fontSize: '3rem'
          }}
        >
          ¿Qué puedes obtener del programa?
        </h1>   
        <p className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
          A través de este&nbsp;
          <b className='app-neutral-black-text'>
            programa
          </b>&nbsp;
          tendrás la posibilidad de vivir el “gozo”; este sentimiento de alegría extendido en el tiempo y la posibilidad de disfrutar y rejocijarse de las cosas que te pasan en la vida.
        </p>
        <p className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
          Encuentra un espacio para reflexionar sobre las situaciones que impiden tu felicidad y trabaja en una serie de prácticas útiles y sencillas que te llevarán a incrementar la felicidad en tu vida.
        </p>
        <h1
          className='app-suomi-hand-script-font app-primary-text py-3'
          style={{
            fontSize: '3rem'
          }}
        >
          ¿Cómo se cursa el programa?
        </h1>   
        <p className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
          Cúrsalo en línea, de manera individual, con una duración recomendada en 1 semana.
        </p>
        <p className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
          Justó aquí en LA GRAN VIDA encontrarás un espacio para cursarlo desde el lugar que elijas, en cualquier dispositivo, en los tiempos que definas.
        </p>
        <p className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
          Te llevaremos de la mano para orientarte a lograr un pilar de gozo adecuado para ti.
        </p>
      </Container>
    </>
  );

  // #endregion

  return (
    <>
      <article className='app-quaternary-bg'>
        <section>
          {detailsTemplate}
        </section>
      </article>
    </>
  );
}

export default ProgramDetailsBox;