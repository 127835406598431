import { Button, Col, Container, Row } from 'react-bootstrap';

function ProgramDetailsBox() {

  // #region Templates

  const detailsTemplate = (
    <>
      <Container className='p-5'>
        <h1
          className='app-suomi-hand-script-font app-primary-text pb-3 pt-5'
          style={{
            fontSize: '3rem'
          }}
        >
          Abundancia ¿Que vas a encontrar?
        </h1>   
        <p className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
          Este pilar es la posibilidad de tomar control de tus finanzas personales y desarrollar la inteligencia financiera necesaria para lograr tus metas económicas, tomar decisiones informadas y alcanzar la estabilidad que financiera que deseas.
        </p>
        <p className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
          ¡Descubre el camino a la abundancia financiera y transforma tu vida!
        </p>
        <h1
          className='app-suomi-hand-script-font app-primary-text py-3'
          style={{
            fontSize: '3rem'
          }}
        >
          ¿Qué puedes obtener del programa?​
        </h1>
        <p className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
          A través de este&nbsp;
          <b className='app-neutral-black-text'>
            programa
          </b>&nbsp;
          tendrás la posibilidad de reflexionar sobre el estado actual de tu salud financiera, retomando lo vívido en el módulo 5 de “40 días para una gran vida”, en este curso te invitamos a realizarlos de nuevo de manera más profunda y consciente.
        </p>
        <p className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
          Te guiaremos paso a paso para que puedas liberarte de las limitaciones y dudas que te impiden alinearte con una mentalidad de abundancia y prosperidad.
        </p>
        <h1
          className='app-suomi-hand-script-font app-primary-text py-3'
          style={{
            fontSize: '3rem'
          }}
        >
          ¿Cómo se cursa el programa?​
        </h1>
        <p className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
          Cúrsalo en línea, de manera individual, con una duración recomendada en 2 semanas.
        </p>
        <p className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
          Justó aquí en LA GRAN VIDA encontrarás un espacio para cursarlo desde el lugar que elijas, en cualquier dispositivo, en los tiempos que definas.
        </p>
        <p className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
          Te llevaremos de la mano para orientarte a tu libertad financiera.
        </p>
      </Container>
    </>
  );

  // #endregion

  return (
    <>
      <article className='app-quaternary-bg'>
        <section>
          {detailsTemplate}
        </section>
      </article>
    </>
  );
}

export default ProgramDetailsBox;