import { Button, Col, Container, Row } from 'react-bootstrap';

function ProgramDetailsBox() {

  // #region Templates

  const detailsTemplate = (
    <>
      <Container className='p-5'>
        <h1
          className='app-suomi-hand-script-font app-primary-text pb-3 pt-5'
          style={{
            fontSize: '3rem'
          }}
        >
          Bienestar ¿De qué trata?
        </h1>
        <p className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
          En este pilar recorreremos el&nbsp;
          <b className='app-neutral-black-text'>
            camino
          </b>&nbsp;
          del bienestar fundamentado en la salud de 4 cuerpos: físico, Mental, Emocional y Espiritual. Aprende técnicas sencillas y probadas para lograr tener una experiencia saludable enfocada a gozar de LA GRAN VIDA.
        </p>
        <h1
          className='app-suomi-hand-script-font app-primary-text py-3'
          style={{
            fontSize: '3rem'
          }}
        >
          ¿Qué puedes obtener del programa?​
        </h1>
        <p className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
          A través de este&nbsp;
          <b className='app-neutral-black-text'>
            programa
          </b>&nbsp;
          tendrás la posibilidad de reflexionar sobre tu estado de salud actual con la visión de los 4 cuerpos, podrás encontrar prácticas sencillas y útiles para desarrollar hábitos que te fortalezcan y te proporciones un estado de bienestar en todas las dimensiones que componen tu “cuerpo”​.
        </p>
        <p className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
          Obtendrás una base que te apoyará a dejar atrás tus creencias, limitaciones y dudas que te impiden alinear tu salud con la vida que buscas crear.
        </p>
        <h1
          className='app-suomi-hand-script-font app-primary-text py-3'
          style={{
            fontSize: '3rem'
          }}
        >
          ¿Cómo se cursa el programa?​
        </h1>
        <p className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
          Cúrsalo en línea, de manera individual, con una duración recomendada en 1 semana.
        </p>
        <p className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
          Justó aquí en LA GRAN VIDA encontrarás un espacio para cursarlo desde el lugar que elijas, en cualquier dispositivo, en los tiempos que definas.
        </p>
        <p className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
          Te llevaremos de la mano para orientarte a lograr un pilar de bienestar adecuado para ti.
        </p>
      </Container>
    </>
  );

  // #endregion

  return (
    <>
      <article className='app-quaternary-bg'>
        <section>
          {detailsTemplate}
        </section>
      </article>
    </>
  );
}

export default ProgramDetailsBox;