import React from "react";
import { Button } from "react-bootstrap";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import RBNavbar from 'react-bootstrap/Navbar';
import translateIcon from '../../../../assets/images/translate/translate-icon.png';
import * as Paths from "../../../../constants/paths";
import { useAuth } from "../../../../hooks/useAuth";
import { useNav } from "../../../../hooks/useNav";
import BrandLogo from "../../common/BrandLogo/BrandLogo";

const Navbar = () => {  

  // #region Vars and Consts

  const nav = useNav();
  const { hasPermission, user, logout } = useAuth();

  // #endregion

  // #region Events

  const onLogoPress = () => {
    nav.navigate(Paths.views.HOME);
  };

  const onProgramsPress = () => {        
    nav.navigate(Paths.views.HOME, Paths.sections.home.PROGRAMS);
  };

  const onEventsPress = () => {
    nav.navigate(Paths.views.HOME, Paths.sections.home.SPECIAL_EVENTS);
  };

  const onPersonalSessionsPress = () => {    
  };

  const onTestimonialsPress = () => {
    nav.navigate(Paths.views.HOME, Paths.sections.home.TESTIMONIALS);
  };

  const onBlogsPress = () => {
    nav.navigate(Paths.views.HOME, Paths.sections.home.BLOGS);
  };

  const onTherapistDirectoryPress = () => {    
  };

  const onContactPress = () => {
    nav.navigate(Paths.views.SIGN_UP);
  };

  const onWhoAreWePress = () => {
    nav.navigate(Paths.views.aboutUs.ABOUT_US, Paths.sections.aboutUs.WHO_ARE_WE);
  };

  const onWhatDefineUsPress = () => {
    nav.navigate(Paths.views.aboutUs.ABOUT_US, Paths.sections.aboutUs.WHAT_DEFINE_US);
  };

  const onWhatDoWeDoPress = () => {
    nav.navigate(Paths.views.aboutUs.ABOUT_US, Paths.sections.aboutUs.WHAT_DO_WE_DO);
  };

  const onWhyDoWeHelpPress = () => {
    nav.navigate(Paths.views.aboutUs.ABOUT_US, Paths.sections.aboutUs.WHY_DO_WE_HELP);
  };

  const onHowDoWeHelpPress = () => {
    nav.navigate(Paths.views.aboutUs.ABOUT_US, Paths.sections.aboutUs.HOW_DO_WE_HELP);
  };

  const onDoYouWantToShareYourProgramOrWorkshopPress = () => {
    nav.navigate(Paths.views.aboutUs.ABOUT_US, Paths.sections.aboutUs.DO_YOU_WANT_TO_SHARE_YOUR_PROGRAM_OR_WORKSHOP);
  };

  const onFAQsPress = () => {
    nav.navigate(Paths.views.aboutUs.FAQS);
  };

  const onLoginPress = () => {
    
  };

  const onTranslatePress = () => {

  };

  const onLogOutPress = () => {
    logout();
    nav.navigate(Paths.views.HOME);
  };

  // #endregion

  // #region Templates

  const programsNavTemplate = (
    <>
      <Nav.Link
        onClick={onProgramsPress}
        className="text-uppercase"
      >
        Programas
      </Nav.Link>
    </>
  );

  const eventsNavTemplate = (
    <>
      <Nav.Link
        onClick={onEventsPress}
        className="text-uppercase"
      >
        Eventos
      </Nav.Link>
    </>
  );

  const personalSessionsNavTemplate = (
    <>
      <Nav.Link
        onClick={onPersonalSessionsPress}
        className="text-uppercase"                
      >
        Sesiones Personales
      </Nav.Link>
    </>
  );

  const aboutUsNavTemplate = (
    <>
      <NavDropdown
        title='Nosotros'
        id="basic-nav-dropdown"
        className="text-uppercase"
      >
        <NavDropdown.Item
          onClick={onWhoAreWePress}
        >
          ¿Quienes somos?
        </NavDropdown.Item>
        <NavDropdown.Divider />
        <NavDropdown.Item
          onClick={onWhatDefineUsPress}
        >
          Lo que nos define
        </NavDropdown.Item>
        <NavDropdown.Divider />
        <NavDropdown.Item
          onClick={onWhatDoWeDoPress}
        >
          ¿Qué hacemos?
        </NavDropdown.Item>
        {/* <NavDropdown.Divider />
        <NavDropdown.Item
          onClick={onWhyDoWeHelpPress}
        >
          ¿Para que ayudamos?
        </NavDropdown.Item>
        <NavDropdown.Divider />
        <NavDropdown.Item
          onClick={onHowDoWeHelpPress}
        >
          ¿Cómo ayudamos?
        </NavDropdown.Item>
        <NavDropdown.Divider />
        <NavDropdown.Item
          onClick={onDoYouWantToShareYourProgramOrWorkshopPress}
        >
          ¿Deseas compartir tu programa o taller?
        </NavDropdown.Item> */}
        <NavDropdown.Divider />
        <NavDropdown.Item
          onClick={onFAQsPress}
        >
          Preguntas Frecuentes
        </NavDropdown.Item>
      </NavDropdown>
    </>
  );
  
  const testimonialsNavTemplate = (
    <>
      <Nav.Link
        onClick={onTestimonialsPress}
        className="text-uppercase"
      >
        Testimoniales
      </Nav.Link>
    </>
  );

  const blogsNavTemplate = (
    <>
      <Nav.Link
        onClick={onBlogsPress}
        className="text-uppercase"
      >
        Blogs
      </Nav.Link>
    </>
  );

  const therapistDirectoryNavTemplate = (
    <>
      <Nav.Link
        onClick={onTherapistDirectoryPress}
        className="text-uppercase"
      >
        Directorio de Terapeutas
      </Nav.Link>
    </>
  );

  const contactNavTemplate = (
    <>
      <Nav.Link
        onClick={onContactPress}
        className="text-uppercase"
      >
        Contacto
      </Nav.Link>
    </>
  );

  const loginNavTemplate = (
    <>
      <Button
        className="text-uppercase app-accent-button mx-2"
        variant='success'
        onClick={onLoginPress}
      >
        Iniciar sesión
      </Button>
    </>
  );

  const translateNavTemplate = (
    <>
      <img
        src={translateIcon}
        height="40"
        width='40'
        className="d-inline-block align-top mx-2"
        alt="Traducir"
        onClick={onTranslatePress}
      />
    </>
  );

  const navbarTemplate = (
    <>
      <RBNavbar
        expand="xl"        
        bg="light"
        data-bs-theme="light"
        fixed="top"
      >
        <Container fluid className="app-quaternary-bg app-montserrat-semi-bold-font">
          <RBNavbar.Brand
            onClick={onLogoPress}
          >
            <BrandLogo />
          </RBNavbar.Brand>
          <RBNavbar.Toggle
            aria-controls="navbar-nav"
          />
          <RBNavbar.Collapse
            id="navbar-nav"
          >
            <Nav
              className="ms-auto"              
            >
              {programsNavTemplate}
              {eventsNavTemplate}
              {/* {personalSessionsNavTemplate} */}
              {aboutUsNavTemplate}
              {testimonialsNavTemplate}
              {blogsNavTemplate}
              {/* {therapistDirectoryNavTemplate} */}
              {contactNavTemplate}
              {loginNavTemplate}
              {translateNavTemplate}
            </Nav>
          </RBNavbar.Collapse>
        </Container>
      </RBNavbar>    
    </>
  );

  // #endregion
  
  return (
    <>
      {navbarTemplate}
    </>
  )
}

export default Navbar;