import * as Paths from "../../../../constants/paths";
import { useNav } from '../../../../hooks/useNav';
import { Button, Card, Col, Container, Row } from 'react-bootstrap';
import underlinePurpleIamge from '../../../../assets/images/underlines/underline-purple.png';

function InvestmentBox() {

  // #region Vars and Consts

  const nav = useNav();

  // #endregion

  // #region Events

  const onSignUpPress = () => {
    nav.navigate(Paths.views.SIGN_UP);
  };

  // #endregion  

  // #region Templates

  const investmentTemplate = (
    <>
      <Container className='p-5'>
        <h2 className='app-pharma-bold-font app-primary-text text-uppercase text-center py-3'>
          Duración e Inversión
        </h2>
        <Row className='pb-4'>
          <Col lg={4}>
            <Card className='my-2 app-tertiary-bg'>
              <Card.Body>
                <Card.Title>
                  <span className='app-montserrat-bold-font text-uppercase'>
                    &nbsp;
                  </span>
                  <h1 className='app-montserrat-bold-font text-center text-uppercase'>
                    2 semanas
                  </h1>
                </Card.Title>
              </Card.Body>
            </Card>
          </Col>
          <Col lg={4}>
            <Card className='my-2 app-primary-bg'>
              <Card.Body>
                <Card.Title className='position-relative text-center'>
                  <span className='app-montserrat-bold-font text-uppercase'>
                    Equivale a
                  </span>
                  <h1 className='app-montserrat-bold-font text-center text-uppercase'>
                    4 sesiones
                  </h1>
                  <h1
                    className='app-suomi-hand-script-font app-accent-text position-absolute'
                    style={{
                      fontSize: '2rem',
                      right: '20%',
                      bottom: '-25px'
                    }}
                  >
                    Al precio de una.
                  </h1>
                </Card.Title>
              </Card.Body>
            </Card>
          </Col>
          <Col lg={4}>
            <Card className='my-2 app-secondary-bg'>
              <Card.Body>
                <Card.Title className='position-relative'>
                  <h1 className='app-montserrat-bold-font app-primary-text text-center text-uppercase'>
                    $ 1,500&nbsp;
                    <span className='h4'>
                      mxp
                    </span>
                  </h1>
                  <img
                    className='w-50 position-absolute'
                    src={underlinePurpleIamge}
                    style={{
                      left: '25%',
                      bottom: '-20px'
                    }}
                  />
                </Card.Title>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <p className='app-montserrat-medium-font app-neutral-white-text text-center fs-4'>
          Está diseñado para que lo puedas concluir en&nbsp;
          <span className='app-tertiary-text'>
            2 semanas
          </span>&nbsp;
          y lo ejerzas para ti todos los días.
        </p>
        <p className='app-montserrat-medium-font app-neutral-white-text text-center fs-4'>
          La inversión es de sólo&nbsp;
          <b className='app-primary-text'>
            1,500 MXP
          </b>.&nbsp;          
        </p>
        <p className='app-montserrat-medium-font app-neutral-white-text text-center fs-4'>
          Como complemento a los&nbsp;
          <b className='app-primary-text'>
            40 días
          </b>,&nbsp;          
          creemos que es una herramienta clave para el futuro que deseas.          
        </p>        
        <Button
          className="text-uppercase app-pharma-bold-font fs-3 p-3 shadow mx-auto d-block"
          variant='primary'
          size='md'
          onClick={onSignUpPress}
        >
          ¡El camino hacia la abundancia financiera Comienza aquí!
        </Button>
      </Container>
    </>
  );

  // #endregion

  return (
    <>
      <article className='app-secondary-bg'>
        <section>
          {investmentTemplate}
        </section>
      </article>
    </>
  );
}

export default InvestmentBox;