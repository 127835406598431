import { useContext, useEffect } from 'react';
import { Navigate, useOutlet } from 'react-router-dom';
import { useAuth } from '../../../hooks/useAuth';
import Navbar from './Navbar/Navbar';
import Sidebar from './Sidebar/Sidebar';
import { AppContext } from '../../../context/appContext';

function Layout() {

  const { viewport } = useContext(AppContext);
  const { user } = useAuth();
  const outlet = useOutlet();

  useEffect(() => {
    console.log("Layout: viewport", viewport);
  }, [viewport])
  return (    
    <>
      {
        !user ? (
          <Navigate to="/" />
        ) : (
          <section>
            <header>
              <Navbar />
            </header>
            <section>
              <div className='row'>
                <div className="col-12 col-sm-2 d-none d-sm-block" >
                  <Sidebar />
                </div>
                <div className="col-12 col-sm-10 p-4">
                  {outlet}
                </div>
              </div>
            </section>
          </section>
        )
      }
    </>
  );
}

export default Layout;
