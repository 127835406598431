import { Carousel } from 'primereact/carousel';
import { Button, Container } from 'react-bootstrap';
import program40DaysIamge from '../../../../assets/images/programs/la-gran-vida-program-40-dias-para-una-gran-vida.jpg';
import programAbundanceIamge from '../../../../assets/images/programs/la-gran-vida-program-abundancia.jpg';
import programLoveIamge from '../../../../assets/images/programs/la-gran-vida-program-amor.jpg';
import programWellnessIamge from '../../../../assets/images/programs/la-gran-vida-program-bienestar.jpg';
import programJoyIamge from '../../../../assets/images/programs/la-gran-vida-program-gozo.jpg';
import * as Paths from "../../../../constants/paths";
import { useNav } from '../../../../hooks/useNav';

function ProgramsBox() {

  // #region Vars and Consts

  const nav = useNav();

  const products = [
    {
      id: 1,
      description: '40 días para una gran vida',
      image: program40DaysIamge,
      onPress: () => {
        nav.navigate(Paths.views.programs.FOURTY_DAYS_FOR_AN_AMAZING_LIFE);
      }
    },
    {
      id: 2,
      description: 'Abundancia',
      image: programAbundanceIamge,
      onPress: () => {
        nav.navigate(Paths.views.programs.ABUNDANCE);
      }
    },
    {
      id: 3,
      description: 'Amor',
      image: programLoveIamge,
      onPress: () => {
        nav.navigate(Paths.views.programs.LOVE);
      }
    },
    {
      id: 4,
      description: 'Bienestar',
      image: programWellnessIamge,
      onPress: () => {
        nav.navigate(Paths.views.programs.WELLNESS);
      }
    },
    {
      id: 5,
      description: 'Gozo',
      image: programJoyIamge,
      onPress: () => {
        nav.navigate(Paths.views.programs.JOY);
      }
    }
  ];

  const responsiveOptions = [
    {
      breakpoint: '1200px',
      numVisible: 3,
      numScroll: 1
    },
    {
      breakpoint: '992px',
      numVisible: 2,
      numScroll: 1
    },
    {
      breakpoint: '768px',
      numVisible: 1,
      numScroll: 1
    },
    {
      breakpoint: '576px',
      numVisible: 1,
      numScroll: 1
    }
  ];

  // #endregion

  // #region Templates

  const programTemplate = (program) => {
    return (
      <div className="text-center m-4 position-relative">
        <img
          src={program.image}
          alt={program.description}
          className="w-100"
          style={{
            borderRadius: '1.5rem'            
          }}
        />      
        <Button
          className="text-uppercase app-pharma-bold-font app-primary-button position-absolute px-3 top-100 start-50 translate-middle"
          variant='primary'
          size='lg'
          style={{
            minWidth: '90%'            
          }}
          onClick={program.onPress ? program.onPress : () => {}}
        >
          {program.description}
        </Button>
      </div>
    );
  };

  const carouselTemplate = (
    <>
      <Container fluid>        
        <Carousel
          value={products}
          numVisible={4}
          numScroll={1}
          responsiveOptions={responsiveOptions}
          itemTemplate={programTemplate}      
          circular
          autoplayInterval={3000}          
        />      
      </Container>
    </>
  );

  // #endregion

  return (
    <>
      <article className='app-secondary-bg'>
        <section>
          <h1 className='app-neutral-white-text app-pharma-bold-font text-uppercase text-center pt-5'>
            Nuestros Programas
          </h1>
          {carouselTemplate}
        </section>
      </article>
    </>
  );
}

export default ProgramsBox;