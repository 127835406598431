import { useLocation, useNavigate } from "react-router-dom";

export const useNav = () => {

  // #region Vars and Consts

  const _navigate = useNavigate();
  const location = useLocation();

  // #endregion

  // #region  Methods

  const navigateToView = (view) => {
    _navigate(view);
  };

  const navigateToSection = (view, section) => {
    if (location.pathname === view) {
      document.getElementById(section).scrollIntoView();
    } else {
      _navigate(view);
      setTimeout(() => {
        document.getElementById(section).scrollIntoView();
      }, 1000);
    }
  };

  const navigate = (view, section) => {
    if (view && section) {
      navigateToSection(view, section);
    } else if (view) {
      navigateToView(view);
    }  
  };

  // #endregion

  return { navigate };
  
};
