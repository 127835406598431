import { Button, Col, Container, Row } from 'react-bootstrap';
import movieEMotionImage from '../../../../assets/images/movies/la-gran-vida-movie-e-motion.jpg';
import movieTheConnectedUniverseImage from '../../../../assets/images/movies/la-gran-vida-movie-the-connected-universe.jpg';
import movieWhatTheBleepDoWeKnowImage from '../../../../assets/images/movies/la-gran-vida-movie-what-the-bleep-do-we-know.jpg';

function BlogDetailsBox() {

  // #region Templates

  const paragraphImageTemplate = (image) => {
    return (
      <>
        <img
          className='d-block d-lg-none float-start shadow m-3'
          src={image}
          style={{
            width: '33%'
          }}
        />
        <img
          className='d-none d-lg-block float-start shadow m-3'
          src={image}
          style={{
            width: '15%'
          }}
        />
      </>
    )
  };

  const detailsTemplate = (
    <>
      <Container className='p-5'>
        <h1
          className='app-suomi-hand-script-font app-primary-text py-3'
          style={{
            fontSize: '3rem'
          }}
        >
          ¿Y tú qué sabes?  (What the Bleep Do We Know!?)
        </h1>         
        <Row>          
          <Col>
            {paragraphImageTemplate(movieWhatTheBleepDoWeKnowImage)}
            <p className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
              Yo considero esta película como “física cuántica para Dummies”. Si estas interesado en comprender un poco sobre cómo funciona esto de la mecánica cuántica y sus implicaciones en la realidad que creamos, es un excelente documental.
            </p>
            <p className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
              El documental busca explicar la realidad combinando creencias místicas y postulados científicos obtenidos de la física, particularmente del principio de incertidumbre de la mecánica cuántica, como también nociones generales de psicología, psiquiatría y química.
            </p>
            <p className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
              Estrenada en febrero del año 2004, combina entrevistas con estilo documental, animación por computadora, nociones generales de física cuántica y ficción para sugerir que la conciencia puede modificar la realidad material.  La trama de la ficción gira en torno a una fotógrafa sordomuda que atraviesa diversas dificultades en su vida cotidiana y que las modifica a lo largo de la historia ya que cambia su  predisposición psicológica frente al medio que la rodea.
            </p>
          </Col>
        </Row>
        <h1
          className='app-suomi-hand-script-font app-primary-text py-3'
          style={{
            fontSize: '3rem'
          }}
        >
          E-motion
        </h1> 
        <Row>
          <Col>
            {paragraphImageTemplate(movieEMotionImage)}
            <p className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
              ¿Eres quién crees ser, o eres el resultado de tus pensamientos? Vivimos atrapados por nuestros pensamientos negativos y las emociones que estos generan. Pero esto puede transformarse si aprendemos cómo hacerlo.
            </p>
            <p className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
              Imagina un mundo donde las emociones atrapadas, los miedos, las ansiedades y las experiencias no procesadas de la vida retenidas en nuestro cuerpo son la fuente de todo lo que nos aflige.
            </p>
            <p className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
              E-Motion crea nuevos paradigmas en la forma en que abordamos nuestra salud.
            </p>
            <p className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
              Científicos muestran cómo las emociones afectan la psicología del cuerpo humano y cómo reemplazar las emociones negativas puede impactar el rendimiento físico.
            </p>
            <p className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
              Únete a expertos de todo el mundo como Sonia Choquette, Joe Dispenza, Nassim Haramein o Neale Donald Walsch y explora con ellos la conexión entre nuestros pensamientos y nuestro bienestar físico y emocional.
            </p>
          </Col>
        </Row>
        <h1
          className='app-suomi-hand-script-font app-primary-text py-3'
          style={{
            fontSize: '3rem'
          }}
        >
          El universo conectado (The connected Universe)
        </h1> 
        <Row>
          <Col>
            {paragraphImageTemplate(movieTheConnectedUniverseImage)}
            <p className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
              <b className='app-montserrat-medium-font app-neutral-black-text fs-4'>
                El UNIVERSO CONECTADO
              </b>&nbsp;
              explora nuevos conocimientos científicos que revelan un panorama de interconexión más amplio de lo que jamás habíamos imaginado.
            </p>
            <p className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
              Esta reveladora película explora cómo la experiencia fundamental del ser humano también tiene que ver con la conexión... y cómo esta experiencia puede verse alterada por estos avances científicos. ¿El viaje a través de estas ideas le permitirá sentirse más “conectado” en su propia vida?
            </p>
            <p className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
              Explora los misterios del universo y las conexiones invisibles que unen todo en&nbsp;
              <b className='app-montserrat-medium-font app-neutral-black-text fs-4'>
                The Connected Universe
              </b>,&nbsp;
              un documental dirigido por Malcom Carter. En esta película del 2016 encontrarás una visión profunda sobre cómo todo en el cosmos está interconectado, desde las estrellas en el cielo hasta los átomos que componen la vida en la Tierra.
            </p>
            <p className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
              Es de las películas favoritas de La Gran Vida, nos ayuda a darnos cuenta de cómo es que lo que yo me hago se lo hago a todos y lo que yo le hago a los demás me lo hago a mí mismo.
            </p>
            <p className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
              Este fascinante viaje de exploración de la conexión de todas las cosas en el Universo está narrado por el legendario Sir Patrick Stewart.
            </p>
          </Col>
        </Row>
      </Container>
    </>
  );

  // #endregion

  return (
    <>
      <article className='app-quaternary-bg'>
        <section>
          {detailsTemplate}
        </section>
      </article>
    </>
  );
}

export default BlogDetailsBox;