import { useEffect } from 'react';
import HeroBannerBox from './HeroBannerBox/HeroBannerBox';
import TermsAndConditionsDetailsBox from './TermsAndConditionsDetailsBox/TermsAndConditionsDetailsBox';

function TermsAndConditionsView() {

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
  }, []);

  return (
    <>
      <article className='app-has-navbar'>
        <section>
          <HeroBannerBox />
        </section>
        <section>
          <TermsAndConditionsDetailsBox />
        </section>
      </article>
    </>
  );
}

export default TermsAndConditionsView;