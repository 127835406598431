import { Container } from 'react-bootstrap';
import * as Paths from "../../../../constants/paths";

function PrivacyPolicyDetailsBox() {

  // #region Templates

  const detailsTemplate = (
    <>
      <Container className='p-5'>         
      
        <h1
          id={Paths.sections.aboutUs.WHO_ARE_WE}
          className='app-anton-regular-font app-secondary-text'
        >
          AVISO DE PRIVACIDAD
        </h1>  

        <p className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
          En cumplimiento de lo dispuesto por la Ley Federal de Protección de Datos Personales en Posesión de los Particulares (en lo sucesivo la “Ley”), el Reglamento de la Ley Federal de Protección de Datos Personales en Posesión de los Particulares (en delante el “Reglamento”) y los Lineamientos del Aviso de Privacidad publicado en el Diario Oficial de la Federación el día 17 de enero de 2013 (en lo sucesivo los “Lineamientos”) y demás disposiciones aplicables, <b className='app-secondary-text'>"LA GRAN VIDA"</b>, sus filiales y subsidiarias desea hacer del conocimiento de sus clientes, proveedores y colaboradores, su Aviso de Privacidad respecto del tratamiento y protección de los datos de carácter personal de aquellas personas físicas o morales que voluntariamente se comunican a través de correo electrónico, telefónicamente o cualquier otro medio electrónico, oral o escrito con <b className='app-secondary-text'>"LA GRAN VIDA"</b>, al proporcionar información, contable, financiera, al proporcionar datos personales, o que llenan formularios en que se recaben datos personales o que ingresen y/o  envíen correos electrónicos en donde  compartan archivos privados, si es que esto implique la comunicación de sus datos personales.
        </p>
        <p className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
          Para todos los efectos relacionados con el presente Aviso de Privacidad, <b className='app-secondary-text'>"LA GRAN VIDA"</b> es el responsable del tratamiento y puede ser localizado en su domicilio ubicado en Cerrada del avellano #824 Cerradas de Anáhuac, en el municipio de General Escobedo, Nuevo León con Código postal 66059 expidiendo y poniendo a disposición del Usuario el presente Aviso de Privacidad, bajo los siguientes términos:
        </p>
        <p className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
          <b className='app-secondary-text'>I. Finalidad del tratamiento de sus datos personales.-</b> La información personal que proporcione el Usuario será utilizada únicamente para brindarle servicios relacionados de manera enunciativa más no limitativa, para la realización de gestiones profesionales  para  dar cumplimiento a las obligaciones fiscales y administrativas de  nuestros clientes,  realizando entre otras actividades específicamente las siguientes: Presentación en tiempo y forma las declaraciones federales y estatales  ante la  Secretaría de Hacienda y Crédito Público y/o Sistema de Administración Tributaria, elaboración de Estados Financieros; asesoría contable-fiscal, administración y elaboración de nómina.
        </p>
        <p className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
          Asimismo, sus datos personales serán utilizados para las siguientes actividades secundarias: (i) análisis para uso interno y elaboración de reportes estadísticos, (ii) control de expedientes de clientes, proveedores y prestadores de servicios, (iii) mercadeo, ofertas y promociones, (iv) determinar su capacidad crediticia y en su caso otorgamiento de crédito, (v) prospección  fiscal y comercial; (vi) servicios relacionados con la cobranza y administración, tales como: facturación, cancelaciones, y gestiones de entrega; (viii) Conocer la experiencia de compra del Usuario a través de encuestas, evaluaciones o similares; (ix) análisis para uso interno del Corporativo; (x) reportes estadísticos; (xi) planeación de recursos humanos y control de expedientes de personal interno y/o externo, proveedores, colaboradores y cualquier figura análoga; y/o (xii) cualquier otra actividad relacionada con el objeto social de <b className='app-secondary-text'>"LA GRAN VIDA"</b>.
        </p>
        <p className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
          Si usted no está de acuerdo con las finalidades secundarias, podrá manifestar su negativa siguiendo el mecanismo previsto en el numeral “VIII” del presente Aviso. Es preciso señalar que en caso de actualizarse el supuesto antes señalado, <b className='app-secondary-text'>"LA GRAN VIDA"</b> no le podrá brindar los servicios que presta de forma correcta y ésta no será responsable por tal situación.
        </p>
        <p className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
          <b className='app-secondary-text'>II. Datos personales solicitados.-</b> Para efectos del presente Aviso de Privacidad se entenderá por dato de carácter personal cualquier información que se recabe concerniente a personas físicas o morales identificadas o identificables; y por Usuario, a cualquier persona física o moral identificada o identificable que comunique sus datos de carácter personal a través de los siguientes medios: i) correo electrónico; ii) llenado de formularios por los que se recaban datos personales; iii) los que comparta  mediante documentos propios que sean entregados en forma personal ; iv) creación de perfiles o cuentas de Usuario; 
        </p>
        <p className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
          Los datos personales que, <b className='app-secondary-text'>"LA GRAN VIDA"</b> recabará del Usuario son: i) Nombre completo; ii) domicilio; iii) correo electrónico; iv) RFC; v) Identificación Oficial; y vi) teléfono; vii) datos contables; viii) Sus datos patrimoniales, cuentas bancarias, créditos , bienes muebles e inmuebles, activos y pasivos;  ix) Información sobre sus empleados, socios, clientes o proveedores; x) Documentos oficiales que acrediten su identidad; xi) CURP ; comprometiéndose a que la información personal o sensible será tratada bajo medidas de seguridad, siempre garantizando la confidencialidad del Usuario.
        </p>
        <p className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
          <b className='app-secondary-text'>III. Resguardo de datos personales.-</b> Los datos personales del Usuario serán resguardados en base a los principios de licitud, consentimiento, información, calidad, finalidad, lealtad, proporcionalidad y responsabilidad, mismos que se encuentran consagrados en la Ley. 
        </p>
        <p className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
          <b className='app-secondary-text'>IV. Transferencia de datos.-</b> <b className='app-secondary-text'>"LA GRAN VIDA"</b> podrá transferir los datos personales del Usuario a personas distintas a esta empresa. En ese sentido, su información puede ser compartida con el sistema de administración tributaria, asociaciones de crédito, solicitantes de referencias crediticias, así como cualquier persona física o moral que a criterio se le pueda entregar dicha información, lo anterior, para investigación crediticia, así como de ser el caso, autoridades o dependencias como: Procuraduría Federal del Consumidor, la Secretaría de Hacienda y Crédito Público; únicamente, cuando dicha situación sea imperativa por una solicitud oficial. Si el Usuario no manifiesta su oposición para que sus datos personales sean transferidos, se entenderá que ha otorgado su consentimiento para ello.
        </p>
        <p className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
          <b className='app-secondary-text'>VI. Obtención de datos.-</b> <b className='app-secondary-text'>"LA GRAN VIDA"</b> manifiesta que podrá obtener información del Usuario de otras fuentes permitidas por la ley, tales como los directorios telefónicos impresos y/o digitales, formularios de datos propios de <b className='app-secondary-text'>"LA GRAN VIDA"</b>, entre otros aplicables, por lo que <b className='app-secondary-text'>"LA GRAN VIDA"</b> y sus filiales y subsidiarias no son ni serán responsables por la veracidad de la información personal que haya proporcionado el Usuario, ni de su verificación y/o actualización, por lo que si el Usuario no solicita el cambio o modificación de información deberá asumirse que la información es precisa y verdadera con todas las implicaciones que esto conlleve, siendo el Usuario el único responsable por la información personal proporcionada y sus consecuencias.
        </p>
        <p className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
          <b className='app-secondary-text'>VII. Cambios o modificaciones al Aviso.-</b> <b className='app-secondary-text'>"LA GRAN VIDA"</b> se reserva el derecho de modificar el presente Aviso por lo que hace de su conocimiento que cualquier cambio o modificación al contenido del presente le será debidamente notificado.
        </p>
        <p className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
          <b className='app-secondary-text'>VIII. Medios para ejercer sus derechos. -</b> De conformidad con lo dispuesto en la Ley, el Usuario podrá acceder, rectificar y cancelar sus datos personales, así como oponerse a la divulgación y limitación del uso de los mismos a través de los procedimientos que <b className='app-secondary-text'>"LA GRAN VIDA"</b> ha implementado a través de su Área Administrativa. El Usuario podrá hacer valer sus derechos de Acceso, Rectificación, Cancelación y Oposición (Derechos “ARCO”), oponerse a la divulgación y limitación de uso de sus datos, así como revocar su consentimiento o manifestar negativa al tratamiento de los mismos, poniéndose en contacto con nuestro departamento de datos personales que tiene su domicilio en Cerrada del Avellano #824 Cerradas De Anahuac en el municipio de General Escobedo, Nuevo León o bien enviando un correo electrónico a <b className='app-secondary-text'><a href="mailto:soporte@lagranvida.com">soporte@lagranvida.com</a></b> dirigido a <b className='app-secondary-text'>"LA GRAN VIDA"</b>, indicando lo siguiente:
        </p>
        <ol
          className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'
          type="I"
        >
          <li>
            Nombre completo del titular de los datos personales.
          </li>
          <li>
            Domicilio o correo electrónico al que podrán dirigirse todas las comunicaciones relacionadas con la solicitud.
          </li>
          <li>
            Copia digitalizada de un documento oficial donde se acredite la identidad del solicitante, pudiendo ser: credencial para votar, licencia de conducir, pasaporte, y/o cualquier otro documento que facilite la localización de datos personales.
          </li>
          <li>
            Descripción clara y precisa de los datos personales respecto de los que se busca ejercer alguno de sus derechos o la actividad sobre la cual de desea limitar el uso de los datos personales.
          </li>
          <li>
            La manifestación expresa para revocar su consentimiento al tratamiento de sus datos personales y por tanto para que no sean usados; y
          </li>
          <li>
            Cualquier otro elemento que facilite la localización de los datos personales.
          </li>
        </ol>        
        <p className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
          <b className='app-secondary-text'>"LA GRAN VIDA"</b> tendrá un período no mayor a 20 (veinte) días hábiles para responder la solicitud por el medio indicado por el Usuario. No obstante, si el usuario considera que han sido vulnerados sus derechos respecto de la protección de datos personales, tiene el derecho de acudir a la autoridad correspondiente para defender su ejercicio. La autoridad es el Instituto Nacional de Transparencia, Acceso a la Información y Protección de Datos Personales (INAI).
        </p>        
        <p className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
          De conformidad con el artículo octavo de la Ley, por el simple hecho de no manifestar oposición al leer el presente Aviso se entenderá su aceptación a los términos del mismo.
        </p>      
        <p className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
          Asimismo, ponemos a su entera disposición copias del presente aviso de privacidad en nuestro domicilio Cerrada del Avellano #824 Cerradas De Anahuac
        </p>        
        <p className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
          General Escobedo, N. L. código postal 66059.
        </p>
      </Container>
    </>
  );

  // #endregion

  return (
    <>
      <article className='app-quaternary-bg'>
        <section>
          {detailsTemplate}
        </section>
      </article>
    </>
  );
}

export default PrivacyPolicyDetailsBox;