import React from "react";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import RBNavbar from 'react-bootstrap/Navbar';
import * as Paths from "../../../../constants/paths";
import { useAuth } from "../../../../hooks/useAuth";
import { useNav } from "../../../../hooks/useNav";
import BrandLogo from "../../common/BrandLogo/BrandLogo";

const Navbar = () => {
  const nav = useNav();
  const { hasPermission, user, logout } = useAuth();

  // #region Events

  const onLogoPress = () => {
    nav.navigate(Paths.views.HOME);
  };

  const onLogOutPress = () => {
    logout();
    nav.navigate(Paths.views.HOME);
  };

  const onHomePress = () => {
    nav.navigate(Paths.views.HOME);
  };

  // #endregion

  // #region Templates

  const dummyNavTemplate = (
    <>
      <Nav.Link
        onClick={onHomePress}
      >
        Dummy
      </Nav.Link>
    </>
  );

  const userNavTemplate = (
    <>
      {
        user && (
          <NavDropdown
            title={user.name}
            id="basic-nav-dropdown"
          >
            <NavDropdown.Divider />
            <NavDropdown.Item
              onClick={onLogOutPress}
            >
              Cerrar Sesión
            </NavDropdown.Item>
          </NavDropdown>
        )
      }
    </>
  );

  const navbarTemplate = (
    <>
      <RBNavbar
        expand="lg"
        className="bg-body-tertiary"
        bg="light"
        data-bs-theme="light"
      >
        <Container>
          <RBNavbar.Brand
            onClick={onLogoPress}>
            <BrandLogo />
          </RBNavbar.Brand>
          <RBNavbar.Toggle
            aria-controls="navbar-nav"
          />
          <RBNavbar.Collapse
            id="navbar-nav"
          >
            <Nav
              className="ms-auto"
            >
              {dummyNavTemplate}
              {userNavTemplate}
            </Nav>
          </RBNavbar.Collapse>
        </Container>
      </RBNavbar>
    </>
  );

  // #endregion
  
  return (
    <>
      <nav>
        {navbarTemplate}
      </nav>      
    </>
  )
}

export default Navbar;