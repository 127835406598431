import React, { createContext, useState, useEffect } from 'react';

export const AppContext = createContext();

const AppContextProvider = (props) => {
  const [viewport, setViewport] = useState(null);

  const getViewport = () => {
    const width = window.innerWidth;
    let viewport;
    if (width < 576) {
      viewport = 'xs';
    } else if (width < 768) {
      viewport = 'sm';
    } else if (width < 992) {
      viewport = 'md';
    } else if (width < 1200) {
      viewport = 'lg';
    } else {
      viewport = 'xl';
    }
    setViewport(viewport);    
  };

  useEffect(() => {
    getViewport();
    window.addEventListener('resize', getViewport);
    return () => {
      window.removeEventListener('resize', getViewport);
    }
  }, []);

  return (
    <>
      <AppContext.Provider value={{
        viewport        
      }}
      >
        {props.children}
      </AppContext.Provider>      
    </>
  )
}

export default AppContextProvider;
