import { Container } from 'react-bootstrap';
import { faQuoteLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function QuoteBox(props) {

  // #region Templates

  const quoteTemplate = (
    <>
      <p className='app-montserrat-regular-italic-font app-neutral-grey-1-text fs-4'>
        <FontAwesomeIcon
          icon={faQuoteLeft}
          className='app-accent-text'
          size='2x'
        />&nbsp;
        {props.quote}
      </p>
    </>
  );

  const authorTemplate = (
    <>
      <p className='app-montserrat-semi-bold-font app-neutral-black-text fs-5'>
        {props.author}
      </p>
    </>
  );

  // #endregion

  return (
    <>
      <article>
        <section>
          <Container fluid>
            <div
              key={props.key}
              className="text-center my-4 mx-5 px-md-5"
            >
              {quoteTemplate}
              {authorTemplate}
            </div>
          </Container>
        </section>
      </article>
    </>
  );
}

export default QuoteBox;