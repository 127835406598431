import { PrimeReactProvider } from 'primereact/api';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { HashRouter, Route, Routes } from "react-router-dom";
import Layout from './components/layouts/Layout/Layout';
import OpenLayout from './components/layouts/OpenLayout/OpenLayout';
import AboutUsView from './components/views/AboutUsView/AboutUsView';
import AbundanceProgramView from './components/views/AbundanceProgramView/AbundanceProgramView';
import FAQsView from './components/views/FAQsView/FAQsView';
import FourtyDaysProgramView from './components/views/FourtyDaysProgramView/FourtyDaysProgramView';
import HomeView from './components/views/HomeView/HomeView';
import JoyProgramView from './components/views/JoyProgramView/JoyProgramView';
import LoveProgramView from './components/views/LoveProgramView/LoveProgramView';
import PrivacyPolicyView from './components/views/PrivacyPolicyView/PrivacyPolicyView';
import RecommendedPodcastsBlogView from './components/views/RecommendedPodcastsBlogView/RecommendedPodcastsBlogView';
import SignInView from './components/views/SignIn/SignIn';
import SignUpView from './components/views/SignUpView/SignUpView';
import TermsAndConditionsView from './components/views/TermsAndConditionsView/TermsAndConditionsView';
import UserHomeView from './components/views/UserHome/UserHome';
import WellnessProgramView from './components/views/WellnessProgramView/WellnessProgramView';
import WhatCanISeeInTheMovieBlogView from './components/views/WhatCanISeeInTheMovieBlogView/WhatCanISeeInTheMovieBlogView';
import WhatsInTheBookBlogView from './components/views/WhatsInTheBookBlogView/WhatsInTheBookBlogView';
import * as Paths from "./constants/paths";
import AppContextProvider from './context/appContext';
import { AuthProvider } from "./hooks/useAuth";
import reportWebVitals from './reportWebVitals';
import './styles/styles.scss';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <AppContextProvider>
      <PrimeReactProvider>
        <HashRouter>      
          <AuthProvider>
            <Routes> 
              <Route element={<OpenLayout />}>
                <Route path={Paths.views.HOME} element={<HomeView />} />
                <Route path={Paths.views.blogs.WHATS_IN_THE_BOOK_OF} element={<WhatsInTheBookBlogView />} />
                <Route path={Paths.views.blogs.RECOMMENDED_PODCASTS} element={<RecommendedPodcastsBlogView />} />
                <Route path={Paths.views.blogs.WHAT_CAN_I_SEE_IN_THE_MOVIE_OF} element={<WhatCanISeeInTheMovieBlogView />} />                
                <Route path={Paths.views.programs.FOURTY_DAYS_FOR_AN_AMAZING_LIFE} element={<FourtyDaysProgramView />} />
                <Route path={Paths.views.programs.ABUNDANCE} element={<AbundanceProgramView />} />
                <Route path={Paths.views.programs.LOVE} element={<LoveProgramView />} />
                <Route path={Paths.views.programs.WELLNESS} element={<WellnessProgramView />} />
                <Route path={Paths.views.programs.JOY} element={<JoyProgramView />} />
                <Route path={Paths.views.aboutUs.ABOUT_US} element={<AboutUsView />} />
                <Route path={Paths.views.aboutUs.FAQS} element={<FAQsView />} />
                <Route path={Paths.views.PRIVACY_POLICY} element={<PrivacyPolicyView />} />
                <Route path={Paths.views.SIGN_UP} element={<SignUpView />} />
                <Route path={Paths.views.TERMS_AND_CONDITIONS} element={<TermsAndConditionsView />} />
                <Route path={Paths.views.LOGIN} element={<SignInView />} />
                <Route path="*" element={<HomeView to="/" replace />} />   
              </Route>
              <Route element={<Layout />}>
                <Route path={Paths.views.USER_HOME} element={<UserHomeView />} />             
                <Route path="*" element={<UserHomeView to="/" replace />} />   
              </Route>         
            </Routes>   
          </AuthProvider>  
        </HashRouter>
      </PrimeReactProvider>
    </AppContextProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
