import { Container } from 'react-bootstrap';
import primaryAbstractBackgroundImage from '../../../../assets/images/backgrounds/la-gran-vida-primary-abstract-bg.png';

function EpilogueBox() {

  // #region Templates

  const investmentTemplate = (
    <>
      <Container
        fluid
        className='p-5 text-center'
        style={{
          background: `url(${primaryAbstractBackgroundImage})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover'
        }}
      >        
        <h1
          className='app-neutral-black-text app-suomi-hand-script-font text-center'
          style={{
            fontSize: '4rem'
          }}
        >
          ¿Qué hay en el libro?
        </h1>
      </Container>
    </>
  );

  // #endregion

  return (
    <>
      <article>
        <section>
          {investmentTemplate}
        </section>
      </article>
    </>
  );
}

export default EpilogueBox;