import { Carousel } from 'primereact/carousel';
import { Container } from 'react-bootstrap';
import * as Paths from "../../../../constants/paths";
import { useNav } from '../../../../hooks/useNav';
import blogKnowingMoreAbout from '../../../../assets/images/blogs/la-gran-vida-blog-conociendo-mas-de.png';
import blogWhatIsInTheBookImage from '../../../../assets/images/blogs/la-gran-vida-blog-que-hay-en-el-libro-de.png';
import blogWhatCanISeeInTheMovieImage from '../../../../assets/images/blogs/la-gran-vida-blog-que-puedo-ver-en-la-pelicula.png';

function BlogsBox() {

  // #region Vars and Consts

  const nav = useNav();

  const blogs = [
    {
      id: 1,      
      image: blogWhatIsInTheBookImage,
      onPress: () => {
        nav.navigate(Paths.views.blogs.WHATS_IN_THE_BOOK_OF);
      }
    },
    {
      id: 2,      
      image: blogKnowingMoreAbout,
      onPress: () => {
        nav.navigate(Paths.views.blogs.RECOMMENDED_PODCASTS);
      }      
    },
    {
      id: 3,      
      image: blogWhatCanISeeInTheMovieImage,
      onPress: () => {
        nav.navigate(Paths.views.blogs.WHAT_CAN_I_SEE_IN_THE_MOVIE_OF);
      }          
    }
  ];
  
  const responsiveOptions = [
    {
      breakpoint: '1200px',
      numVisible: 1,
      numScroll: 1
    },
    {
      breakpoint: '992px',
      numVisible: 2,
      numScroll: 1
    },
    {
      breakpoint: '768px',
      numVisible: 1,
      numScroll: 1
    },
    {
      breakpoint: '576px',
      numVisible: 1,
      numScroll: 1
    }
  ];

  // #endregion

  // #region Templates

  const blogTemplate = (blog) => {
    return (
      <div className="text-center m-4">
        <img
          src={blog.image}
          alt={blog.title}
          className="w-100 shadow"
          style={{
            borderRadius: '1.5rem',
            cursor: 'pointer'
          }}
          onClick={blog.onPress ? blog.onPress : () => { }}
        />  
      </div>
    );
  };

  const carouselTemplate = (
    <>
      <Container fluid>        
        <Carousel
          value={blogs}
          numVisible={4}
          numScroll={1}
          responsiveOptions={responsiveOptions}
          itemTemplate={blogTemplate}
          circular
          autoplayInterval={3000}       
        />      
      </Container>
    </>
  );

  // #endregion

  return (
    <>
      <article className='app-quaternary-bg'>
        <section>
          <h1 className='app-primary-text app-pharma-bold-font text-uppercase text-center pt-5'>
            Blogs
          </h1>
          {carouselTemplate}
        </section>
      </article>
    </>
  );
}

export default BlogsBox;