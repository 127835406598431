import { Navigate, useOutlet } from 'react-router-dom';
import { useAuth } from '../../../hooks/useAuth';
import Navbar from './Navbar/Navbar';
import Footer from '../common/Footer/Footer';

function OpenLayout(props) {
  const { user } = useAuth();
  const outlet = useOutlet();

  return (
    <>
      {
        user ?
          (
            <Navigate to="UserHome" replace />
          ) :
          (
            <>
              <nav>
                <Navbar />
              </nav>
              <main>
                {outlet}
              </main>            
              <footer>
                <Footer />
              </footer>
            </>
          )
      }      
    </>
  );
}

export default OpenLayout;
